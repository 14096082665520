<template>
    <div>
        <div v-for='(creneaux) in creneauxArray' :key="creneaux.id" class="legendCell">
            <span v-if="Number.isInteger(creneaux.start)" class="borderLegend">{{parseHour(creneaux.start)}}</span>
            <span v-else-if="creneaux.start == 23.75" class="borderLegendBottom">00h</span>
            <span v-else></span>
        </div>
    </div>
</template>

<script>
export default {
    name:"PlanningLegend",
    computed: {
        //creation d'un array de creneaux pour une journée (par 15 min, une journée = 96)
        creneauxArray: function() {
            let array = [];
            for (let index = 0; index < 96; index++) {
                const element = {id: index, start: (0 + (0.25 * index))};
                array.push(element)
            }
            return array
        }
    },
    methods: {
        //parse l'heure pour ajouter un leading 0
        parseHour(num){
            if(num < 10){
                return "0"+num+"h";
            } else {
                return num+"h";
            }
        }
    },
}
</script>

<style lang="scss">
    .legendCell {
        height:15px;
        font-size:12px;
        position:relative;
        span {
            display:block;
            width:100%;
            font-size:14px;
            text-align:right;
            @media (-webkit-device-pixel-ratio: 1.25) {
                font-size:0.8vw;
            }
        }
        span.borderLegend {
            position:absolute;
            bottom:100%;
            transform:translateY(50%);
        }
        span.borderLegendBottom {
            position:absolute;
            top:100%;
            transform:translateY(-50%);
        }

    }
</style>