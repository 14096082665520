<template>
    
        <v-menu
            open-on-hover
            bottom
            offset-x
            >
            <template v-slot:activator="{ on, attrs }">
                <div class="tooMuchRdv" :style="getStyle()"
                dark
                v-bind="attrs"
                v-on="on"
                >
                    +{{rdvs.length}}
                </div>
            </template> 
            <v-list>
                <v-list-item
                v-for="(item, index) in rdvs"
                :key="index"
                >
                <v-list-item-title>{{ item.start }} - {{item.end}}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    
</template>
<script>
import dateManipulation from '@/mixins/dateManipulation'
export default {
    name:"PlanningTooMuchRdvComponent",
    mixins:[dateManipulation],
    props: ["rdvs"],
    data() {
        return {
            
        }
    },
    methods: {
        getStyle(){
            let start = 0;
            let end = 0; 
            let top = 0;
            if(this.rdvs && this.rdvs.length > 0){
                this.rdvs.forEach(element => {
                    if(start == 0 ||  this.parseHourToFloat(element.start) < start){
                        start =  this.parseHourToFloat(element.start)
                    }
                    if(end == 0 ||  this.parseHourToFloat(element.end) > end){
                        end =  this.parseHourToFloat(element.end)
                    }
                    if(top == 0 || element.top < top){
                        top = element.top
                    }
                });
                let height = (end - start) * 60
                return "top:"+top+"px; height:"+height+"px;"
            }
            
        }
    },
}
</script>
<style lang="scss">
    .tooMuchRdv {
        position:absolute;
        width: calc( 90% / 4) ;
        left: calc(calc( 90% / 4) * 3);
        z-index:10;
        border-radius: 3px;
        background-color: #CCC;
        color:#888;
        font-size:20px;
        display:flex;
        align-items: center;
        justify-content: center;
    }
</style>