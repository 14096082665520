<template>
    <v-dialog width="600" v-model="displaySelectRessources" @click:outside="$emit('closeRessource')">
        <v-card>
            <v-toolbar tile flat dark  :style="'background-image:url('+publicPath+'img/blue-background.jpg); background-size:cover;'">
                <v-toolbar-title>{{$t('choiceRessourceTitle')}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn outlined depressed small class="mr-2" @click="$emit('closeRessource')">{{$t('cancelLabel')}}</v-btn>
                <v-btn outlined depressed small @click="confirmRessource">{{$t('confirmLabel')}}</v-btn>
            </v-toolbar>
            <v-card-text>
                <h4 style="border-bottom:0; margin-bottom:0" class="pb-0 mt-4">{{$t('availableRessourceOnThisHours')}}</h4>
                <div class="ressourcesItems">
                    <div v-for="ressource in ressources" @click="selectRessource(ressource)" :key="ressource.id" :class="selectedRessource.id == ressource.id ? 'ressourceSelectorItem selected' : (!ressource.active ? 'ressourceSelectorItem unavailableItem' : 'ressourceSelectorItem') ">
                        <div class="selected" v-if="selectedRessource.id == ressource.id"></div>
                        <div class="ml-3 ressourceLine">
                            <div class="name">
                                {{ressource.label}}
                            </div>
                            <div class="icons">
                                <v-icon small v-for="spot in ressource.spots" :key="spot" :color="selectedRessource.id == ressource.id ? 'success' : ''">{{(ressource.taken > spot) ? "$user_s" : "$user_l"}}</v-icon>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    name: "SelectRessourceRdvDialogComponent",
    props:['displaySelectRessources', 'ressources', 'ressourceSelected'],
    data() {
        return {
            publicPath: process.env.BASE_URL,
            selectedRessource: {},
        }
    },
    created() {
        if(this.ressourceSelected){
            this.selectedRessource = this.ressourceSelected
        }
    },
    methods: {
        selectRessource(ressource){
            if(this.selectedRessource.id != ressource.id){
                if(ressource.active){
                    this.selectedRessource = ressource
                }
            } else {
                this.selectedRessource = {}
            }
        },
        confirmRessource(){
            this.$emit('selectRessource', this.selectedRessource )
        }
    },
}
</script>
<style lang="scss">
    
</style>