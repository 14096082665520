<template>
        <v-menu
            top
            offset-x
            :open-on-hover="tooltip"
            :open-on-click="tooltip"
            >
                <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                                <vc-donut :background="background" :size="70" :sections="sections" :auto-adjust-text-size="false">
                                        <div>
                                                <b style="font-size:18px;">{{ stats ? stats.nbQualified + stats.nbConfirmed + stats.nbUnconfirmed : 0 }}</b>
                                        </div>
                                        /{{ stats ? stats.nbTotal - stats.nbBlocked - stats.nbExternal : 0 }}
                                </vc-donut>
                        </div>
                </template>

                <v-card >
                        <v-card-title class="legend">
                                <p><span class="legendItem successLegend mr-2"></span>{{stats ? stats.nbQualified : 0 }} Rendez-vous qualifiés </p>
                                <p><span class="legendItem primaryLegend mr-2"></span>{{stats ? stats.nbConfirmed : 0 }} Rendez-vous confirmés </p>
                                <p><span class="legendItem yellowLegend mr-2"></span>{{stats ? stats.nbUnconfirmed : 0 }} Rendez-vous à confirmer </p>
                                <p><span class="legendItem greyLegend mr-2"></span>{{stats ? stats.nbDispo : 0 }} Capacité restante </p>
                                <p><span class="legendItem redLegend mr-2"></span>{{stats ? stats.nbBlocked : 0 }} Créneaux inutilisables </p>
                                <p><span class="legendItem darkgreyLegend mr-2"></span>{{stats ? stats.nbExternal : 0 }} Rendez vous externe </p>
                        </v-card-title>
                </v-card>
        </v-menu>
</template>
<script>
export default {
        name:"DonutCSS",
        props: ['stats', 'tooltip', 'background'],
        data() {
                return {
                        
                }
        },
        computed: {
                sections(){
                        return [
                                {value: this.qualified*100, color: '#5bb85d'},
                                {value: this.confirmed*100, color: '#161459'},
                                {value: this.unconfirmed*100, color: '#efad4d'},
                                {value: this.remaining*100, color: '#d2d7dd'},
                                {value: this.indispo*100, color: '#b74343'},
                                {value: this.external*100, color: '#333333'}
                        ]
                },
                confirmed() {
                        if(this.stats){
                                return this.stats.nbConfirmed / this.stats.nbTotal
                        } else {
                                return 0
                        }
                },
                unconfirmed() {
                        if(this.stats){
                                return this.stats.nbUnconfirmed / this.stats.nbTotal
                        } else {
                                return 0
                        }
                        
                },
                remaining() {
                        if(this.stats){
                               return this.stats.nbDispo / this.stats.nbTotal
                        } else {
                                return 0
                        }
                        
                },
                indispo() {
                        if(this.stats){
                                return this.stats.nbBlocked / this.stats.nbTotal
                        } else {
                                return 0
                        }
                        
                },
                external() {
                        if(this.stats){
                                return this.stats.nbExternal / this.stats.nbTotal
                        } else {
                                return 0
                        }
                },
                qualified() {
                        if(this.stats){
                                return this.stats.nbQualified / this.stats.nbTotal
                        } else {
                                return 0
                        }
                }
        },
        methods: {
                getDecimal(str, val){
                        str = str.toString();
                        str = str.slice(0, (str.indexOf(".")) + val + 1); 
                        return Number(str);    
                }
        },      
}
</script>
<style lang="scss">
        .legend {
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                p {
                        display:flex;
                        align-items: center;
                        margin-bottom:5px;
                }
                .legendItem {
                        display:block;
                        width:10px; 
                        height:10px;
                        border-radius: 50%;
                        &.successLegend {
                                background-color: var(--v-success-base);
                        }
                        &.primaryLegend {
                            background-color: var(--v-primary-base);
                        }
                        &.blueLegend {
                            background-color: #155e96;
                        }
                        &.yellowLegend {
                                background-color: #d89c33;
                        }
                        &.greyLegend {
                                background-color: #d2d7dd;
                        }
                        &.redLegend {
                                background-color: #b74343;
                        }
                        &.darkgreyLegend {
                                background-color: #333 ;
                        }
                }
        }

</style>