<template>
    <v-container fluid>
        <v-row>
            <div class="contentCalendar">
                <v-container fluid>
                    <v-row justify="start"
                        v-show="type == 'PLANNING' || type == 'INDISPO_USER' || type == 'INDISPO_COACH' || type == 'ADD_RDV_REQUEST' || type == 'EDIT_RDV_REQUEST' || type == 'EDIT_RDV_DATE_REQUEST' || type == 'DISPLAY_OPERATION_RDV'"
                        :class="(multi) ? 'align-center pa-3 headingFilters multi' : 'align-center pa-3 headingFilters'">
                        <v-col cols="4" class="pa-0 d-flex align-center">
                            <v-icon
                                v-if="(daysNumber > 7 || ((type == 'INDISPO_USER' || type == 'INDISPO_COACH') && daysNumber >= 7) || (daysNumber == 7 && operations[0] && new Date(operations[0].config.date_start).getDay() != 1))"
                                link large color="darkgrey" class="mr-3" @click="previousPage">
                                $arrow_alt_circle_left_s
                            </v-icon>
                            <v-icon
                                v-if="daysNumber > 7 || ((type == 'INDISPO_USER' || type == 'INDISPO_COACH') && daysNumber >= 7) || (daysNumber == 7 && operations[0] && new Date(operations[0].config.date_start).getDay() != 1)"
                                dark large color="darkgrey" class="mr-3" @click="nextPage">
                                $arrow_alt_circle_right_s
                            </v-icon>
                            <v-menu v-model="datepicker" :close-on-content-click="false" :nudge-right="40"
                                transition="scale-transition" offset-y min-width="auto"
                                v-if="daysNumber >= 7 || ((type == 'INDISPO_USER' || type == 'INDISPO_COACH') && daysNumber >= 7)">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="frDate" label="Semaine" append-icon="$calendar_alt_l"
                                        readonly dense solo hide-details filled width="150" v-bind="attrs" v-on="on"
                                        class="textfieldDate mr-3 "></v-text-field>
                                </template>
                                <v-date-picker color="primary" v-model="firstDaySQL" locale="fr" first-day-of-week="1"
                                    @input="changeFirstDate"></v-date-picker>
                            </v-menu>
                            <v-select hide-details dense filled solo :menu-props="{ offsetY: true }"
                                :readonly="(places && places.length == 1) "
                                v-if="places && places.length > 0" :items="places" v-model="place"
                                :label="$t('selectPlaceLabel')" :no-data-text="$t('no-data-text')"></v-select>
                            <v-btn-toggle hide-details mandatory borderless v-model="forcedDaysNumber" class="ml-3"
                                style="box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);"
                                active-class="btnActive">
                                <v-btn class="btnSpec ma-0" style="background-color: white; height: 40px;"
                                    :value="daysNumber">Semaine</v-btn>
                                <v-btn class="btnSpec ma-0" style="background-color: white; height: 40px;"
                                    :value="1">Jours</v-btn>
                            </v-btn-toggle>
                        </v-col>
                        <v-col cols="3" class="pa-0">

                        </v-col>
                    </v-row>
                    <div class="multiMenuContent" style="overflow:hidden; overflow-y:auto;"
                        :style="places && places.length > 0 ? '' : 'top:0px; height:100%;'"
                        v-if="teamPlannings && teamPlannings.length > 0 && type != 'OPERATION_HOURS' && type != 'INDISPO_USER' && type != 'INDISPO_COACH'"
                        :key="multiKey">
                        <v-card @click="selectVendor('all')"
                            :style="vendorSelected == 'all' ? 'border-color:#161459;' : 'border-left-color:rgba(0,0,0,0.4);'"
                            :class="vendorSelected == 'all' ? 'vendorItem selected ma-2' : 'vendorItem ma-2'">
                            <div>
                                <DonutCSS :stats="allStats" :background="vendorSelected == 'all' ? 'white' : 'white'"
                                    :tooltip="true" />
                            </div>
                            <div class="name ml-3">
                                <v-icon small class="mr-2">$userfriends_d</v-icon>
                                Planning
                            </div>

                        </v-card>
                        <v-card v-for="vendor in teamPlannings" @click="selectVendor(vendor.id)" :key="vendor.id"
                            :style="vendorSelected == vendor.id ? 'border-color:' + vendor.color + ';' : 'border-left-color:' + vendor.color + ';'"
                            :class="vendorSelected == vendor.id ? 'vendorItem selected ma-2' : 'vendorItem ma-2'">
                            <div>
                                <DonutCSS :stats="vendor.stats"
                                    :background="vendorSelected == vendor.id ? 'white' : 'white'" :tooltip="true" />
                            </div>

                            <v-tooltip right
                                v-if="contact && contact.assignment_user_id && contact.assignment_user_id.id && contact.assignment_user_id.id == vendor.id">
                                <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs" v-on="on" class="ml-3">
                                        <div class="name">
                                            <v-icon small class="mr-2" :color="vendor.color">$fullstar_s</v-icon>
                                            {{ vendor.firstname }} {{ vendor.name }}
                                            <v-icon small :color="vendor.confVisio ? 'success' : 'darkgrey'"
                                                class="ml-3">{{ vendor.confVisio ? '$webcam_r' :
                                                '$webcam_slash_r'}}</v-icon>
                                        </div>
                                        <p class="annotation">{{ vendor.annotation }}</p>
                                        <v-chip class="mr-2" x-small outlined color="success"
                                            v-for="(univers, index) in vendor.univers" :key="'univers_' + index">
                                            {{ univers }}
                                        </v-chip>
                                        <v-chip class="mr-2" x-small outlined color="primary"
                                            v-for="(brand, index) in vendor.brands" :key="'brand_' + index">
                                            {{ brand }}
                                        </v-chip>
                                    </div>
                                </template>
                                <span>{{ $t("assignedVendorLabel") }}</span>
                            </v-tooltip>
                            <div class="ml-3" v-else>
                                <div class="name">
                                    {{ vendor.firstname }} {{ vendor.name }}
                                    <v-icon small :color="vendor.confVisio ? 'success' : 'darkgrey'"
                                        class="ml-3">{{ vendor.confVisio ?
                                        '$webcam_r' : '$webcam_slash_r'}}</v-icon>
                                </div>
                                <p class="annotation">{{ vendor.annotation }}</p>
                                <v-chip class="mr-2" x-small outlined color="success"
                                    v-for="(univers, index) in vendor.univers" :key="'univers_' + index">
                                    {{ univers }}
                                </v-chip>
                                <v-chip class="mr-2" x-small outlined color="primary"
                                    v-for="(brand, index) in vendor.brands" :key="'brand_' + index">
                                    {{ brand }}
                                </v-chip>
                            </div>

                        </v-card>
                    </div>
                    <!-- <v-row ref="header" class="headingPlanning pt-3">
                        <div class="multiMenu" v-if="teamPlannings && teamPlannings.length > 0  && type!='OPERATION_HOURS' && type!='INDISPO_USER'"></div>
                        <div class="legendCalendar"></div>
                        <v-col v-for='(date, index) in dateArray' v-show="type!='INDISPO_CONCESSION' && type!='OPERATION_HOURS'" :key="'header_'+index" class="weekDates text-center">
                            {{date}}
                        </v-col>
                        <v-col v-for='(date, index) in dateArray' v-show="type=='OPERATION_HOURS' && daysNumber < 7" :key="'headerdate2_'+index" class="weekDates text-center">
                            {{date}}
                        </v-col>
                        <v-col v-for='(date, index) in dateArray' v-show="type=='INDISPO_CONCESSION' ||  (type=='OPERATION_HOURS' && daysNumber >= 7)" :key="'headerText_'+index" class="weekDates text-center">
                            {{days[(new Date(parseFrDateToUs(date))).getDay()]}}
                            <v-icon @click="dayFullUnavailable((new Date(parseFrDateToUs(date))).getDay())" v-if='type!="OPERATION_HOURS" && getUnavailableSlots(date, operationsArray, genericUnavailable, type).length == 0' class='ml-3' color="warning" small dark>$calendarplus_s</v-icon>
                            <v-icon @click="dayClearUnavailable((new Date(parseFrDateToUs(date))).getDay())" class='ml-3' v-if='type!="OPERATION_HOURS" && getUnavailableSlots(date, operationsArray, genericUnavailable, type).length > 0' color="success" small dark>$calendartimes_s</v-icon>
                        </v-col>
                        <div class="scrollOffset"></div>
                    </v-row> -->
                    <!-- <v-row :style="getHeight"  v-if="type=='PLANNING'">
                        <div class="multiMenu" v-if="teamPlannings && teamPlannings.length > 0 && type!='OPERATION_HOURS' && type!='INDISPO_USER'"></div>
                        <div class="legendCalendar"></div>
                        <v-col class="pa-0 chipsCol" :style="getHeight">
                            <v-chip small :style="getStyle(operation)" :class="getColor(operation)" v-for="operation in operationsArray"  :key="'operation_'+operation.id">{{operation.type}} - {{operation.name}}</v-chip>
                        </v-col>
                        <div class="scrollOffset"></div>
                    </v-row> -->
                    <v-card class="mt-3" :class="mode == 'popup' ? 'no-shadow' : ''"
                        :style="((teamPlannings && teamPlannings.length > 0 && type != 'OPERATION_HOURS' && type != 'INDISPO_USER' && type != 'INDISPO_COACH') ? 'width:80%; left:20%;' : 'width:100%;')">
                        <v-card-text class="daysRowCalendar pt-0 mt-4" :style="height" ref="inner">
                            <div class="innerCalendar pa-4 pt-0">
                                <div class="legendCalendar pt-5">
                                    <div style="position:sticky; z-index:9; top:0;" class="py-2"></div>
                                    <PlanningLegend />
                                </div>
                                <v-col v-for='(date, index) in dateArray' :key="index" class="colDays px-0 pt-0"
                                    style="position:relative;" :ref="'day_' + date.split('/').join('')">
                                    <div style="position:sticky; z-index:8; top:0; background-color:rgba(255,255,255) !important; text-align: center;"
                                        class="py-2">
                                        <span v-if="type != 'INDISPO_CONCESSION' && type != 'OPERATION_HOURS'">
                                            <b>{{ date }}</b>
                                        </span>
                                        <span v-if="type == 'OPERATION_HOURS' && daysNumber < 7">
                                            <b>{{ date }}</b>
                                        </span>
                                        <span
                                            v-if="type == 'INDISPO_CONCESSION' || (type == 'OPERATION_HOURS' && daysNumber >= 7)">
                                            <b>{{ days[(new Date(parseFrDateToUs(date))).getDay()] }}</b>
                                            <v-icon
                                                @click="dayFullUnavailable((new Date(parseFrDateToUs(date))).getDay())"
                                                v-if='type != "OPERATION_HOURS" && getUnavailableSlots(date, operationsArray, genericUnavailable, type).length == 0'
                                                class='ml-3' color="warning" small dark>$calendarplus_s</v-icon>
                                            <v-icon
                                                @click="dayClearUnavailable((new Date(parseFrDateToUs(date))).getDay())"
                                                class='ml-3'
                                                v-if='type != "OPERATION_HOURS" && getUnavailableSlots(date, operationsArray, genericUnavailable, type).length > 0'
                                                color="success" small dark>$calendartimes_s</v-icon>
                                        </span>
                                    </div>

                                    <PlanningDay :dayText="date" :date="parseFrDateToUs(date)" ref="day"
                                        :hasOperationHere="hasOperation(date, operationsArray)"
                                        :unavailableSlots="getUnavailableSlots(date, operationsArray, genericUnavailable, type)"
                                        :unavailableIsLocked="isMine ? false : true" :creneau="creneau"
                                        :appointements="filteredAppointements" :isRdvDragging="isRdvDragging"
                                        :type="type" :rdvId="draggedRdvId" :isSettingUnavailable="isSettingUnavailable"
                                        :multi="multi" :places="places" :vendorSelected="vendorSelected"
                                        :rdvIsEdited="isRdvEditDialogOpen" :popup="popup" @addItem="addItem"
                                        @editItem="editItem" @editAppointement="editAppointement"
                                        @removeUnavailable="removeUnavailable" @deleteIndispo="deleteUserIndispo"
                                        @settingUnavailable="settingUnavailable" @editRdv="editRdv"
                                        @recordInfos="recordInfos" :key="'planningDay_' + index + '_' + planningDayKey" />
                                </v-col>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-container>
            </div>
        </v-row>
    </v-container>
</template>

<script>
import PlanningDay from './PlanningDay'
import PlanningLegend from './PlanningLegend'
import dateManipulation from '@/mixins/dateManipulation'
import planningMixins from '@/mixins/planningMixins'
import DonutCSS from '@/components/DonutCSS'
import GenericDataService from '@/services/GenericDataService'
export default {
    name: "PlanningCalendar",
    props: ["startDate", "daysNumber", "type", "isMine", "creneau", "contact", "appointements", "operations", "operationId", "genericUnavailable", "mode", "vendeurId", "popup", "superlead"],
    mixins: [dateManipulation, planningMixins],
    components: {
        PlanningDay, PlanningLegend, DonutCSS
    },
    data() {
        return {
            datepicker: false,
            refDate: null,
            isRdvDragging: false,
            isRdvEditDialogOpen: false,
            draggedRdvId: 0,
            default_colors: ['#3366CC', '#DC3912', '#FF9900', '#109618', '#990099', '#3B3EAC', '#0099C6', '#DD4477', '#66AA00', '#B82E2E', '#316395', '#994499', '#22AA99', '#AAAA11', '#6633CC', '#E67300', '#8B0707', '#329262', '#5574A6', '#3B3EAC'],
            default_borders: ['#2b57b0', '#b93414', '#c57702', '#0e7c15', '#760176', '#2e3080', '#0481a6', '#ae385f', '#508501', '#932727', '#28527b', '#7d397d', '#1b8275', '#8d8d11', '#562daa', '#bc5e00', '#6f0707', '#29744f', '#466089', '#2a2c78'],
            vendorSelected: "all",
            firstDay: "",
            height: "",
            dayWidth: 0,
            isSettingUnavailable: false,
            multi: [],
            filteredAppointements: [],
            places: [],
            place: 0,
            //moveTo : variable pour charger le planning scrollé a cette heure la
            moveTo: 8,
            appWidth: window.innerWidth,
            days: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
            planningDayKey: 0,
            frDate: null,
            multiKey: 0,
            socketServer: null,
            forcedDaysNumber: null
        }
    },
    computed: {
        teamPlannings() {
            if (this.place != 0) {
                let placeSelected = this.places.find((e) => e.value == this.place)
                return this.multi.filter((e) => placeSelected.users.includes(e.id))
            } else {
                return this.multi
            }
        },
        allStats() {
            let stats = {
                nbBlocked: 0,
                nbConfirmed: 0,
                nbDispo: 0,
                nbExternal: 0,
                nbQualified: 0,
                nbTotal: 0,
                nbUnconfirmed: 0,
                ratioFree: 1
            }
            let placeSelected = this.places.find((e) => e.value == this.place);
            if (placeSelected)
                var users = this.multi.filter((e) => placeSelected.users.includes(e.id));

            if (!users) {
                users = this.multi
            }
            users.forEach((element) => {
                if (element.stats && element.stats.nbDispo)
                    stats.nbDispo += element.stats.nbDispo
                if (element.stats && element.stats.nbBlocked)
                    stats.nbBlocked += element.stats.nbBlocked
                if (element.stats && element.stats.nbConfirmed)
                    stats.nbConfirmed += element.stats.nbConfirmed
                if (element.stats && element.stats.nbExternal)
                    stats.nbExternal += element.stats.nbExternal
                if (element.stats && element.stats.nbQualified)
                    stats.nbQualified += element.stats.nbQualified
                if (element.stats && element.stats.nbTotal)
                    stats.nbTotal += element.stats.nbTotal
                if (element.stats && element.stats.nbUnconfirmed)
                    stats.nbUnconfirmed += element.stats.nbUnconfirmed
            })

            return stats;

        },
        allRdvNumber() {
            let nbRdv = 0

            this.multi.forEach((element) => {
                if (element.stats && element.stats.nbDispo)
                    nbRdv += element.stats.nbDispo
            })
            return nbRdv
        },
        allConfirmedNumber() {
            let nbConfirmed = 0
            this.multi.forEach((element) => {
                if (element.stats && element.stats.nbConfirmed)
                    nbConfirmed += element.stats.nbConfirmed
            })
            return nbConfirmed
        },
        allUnconfirmedNumber() {
            let nbUnconfirmed = 0
            this.multi.forEach((element) => {
                if (element.stats && element.stats.nbUnconfirmed)
                    nbUnconfirmed += element.stats.nbUnconfirmed
            })
            return nbUnconfirmed
        },
        allRdvToFinished() {
            let unqualified = this.appointements.filter((e) => e.status == 'FINISHED')
            return unqualified.length
        },
        // Création d'un array de date dépendant de la date du début et le nombre de jours à afficher
        // Duplication de la fonction qu'il y a dans Planning.vue car la valeur du premier jour change lorsque l'on change de page
        dateArray: function () {
            if (this.daysNumber == 1 || this.forcedDaysNumber == 1) {
                return [new Intl.DateTimeFormat('fr-FR').format(new Date(this.firstDay))]
            } else {
                const array = [];
                for (let index = 0; index < this.daysNumber; index++) {
                    let element = new Date(new Date(this.firstDay).getTime() + (index * 24 * 60 * 60 * 1000));
                    array.push(new Intl.DateTimeFormat('fr-FR').format(element))
                }
                return array
            }

        },
        //création d'un sous array des opérations avec les informations nécessaire pour générer les chips
        operationsArray: function () {
            //getOperationArray est une fonction globale définie dans mixins/planningMixins
            let array = []
            if (this.operations) {
                array = this.getOperationsArray(this.operations, this.dateArray);
            }
            return array;
        },
        //Calcul de la hauteur de la partie "operation" avec les chips d'opérations
        getHeight: function () {
            let height = this.operationsArray.length > 0 ? (this.operationsArray.length * 24 + (this.operationsArray.length) * 2) : 0;
            return "height:" + height + "px;";
        },
        // firstDaySQL(){
        //     this.frDate = this.parseUsDateToFr(this.parseJsDateToUs(this.firstDay))
        //     return this.parseJsDateToUs(this.firstDay)
        // },
        firstDaySQL: {
            get: function () {
                this.frDate = this.parseUsDateToFr(this.parseJsDateToUs(this.firstDay))
                return this.parseJsDateToUs(this.firstDay)
            },
            set: function (newValue) {
                this.firstDay = new Date(newValue);
                this.frDate = this.parseUsDateToFr(newValue)
            }
        },
    },
    methods: {
        scrolling(e) {
            e.preventDefault();
            return false;
        },
        recordInfos(infos) {
            this.isRdvEditDialogOpen = false
            this.$emit('recordInfos', infos)
        },
        editRdv(value) {
            this.isRdvEditDialogOpen = value
        },
        selectVendor(idVendor) {
            this.refreshStat()
            this.filteredAppointements = []
            let vendorIds = []
            if (idVendor == "all") {
                this.vendorSelected = "all"
                this.filteredAppointements = this.appointements
                vendorIds = this.teamPlannings.map((e) => e.id)
            } else {
                this.vendorSelected = idVendor
                this.filteredAppointements = this.appointements.filter((e) => e.links && e.links.user && parseInt(e.links.user[0]) == idVendor)
                vendorIds = [parseInt(idVendor)]
            }
            this.$emit("vendorSelect", vendorIds, new Date(this.firstDay), this.place)
        },
        deleteUserIndispo(slot) {
            if (this.type == "INDISPO_USER" || this.type == "INDISPO_COACH") {
                this.$emit('deleteUserIndispo', slot)
            }
        },
        changeFirstDate() {
            // let currentDate = new Date(this.refDate)
            // this.firstDay = new Date(currentDate.setDate(currentDate.getDate() - (currentDate.getDay() == 0 ? 6 : currentDate.getDay() - 1)     )).toUTCString();
            this.datepicker = false

            this.$router.replace({ 'query': { 'from': this.firstDaySQL } })
        },
        //Event pour mettre toute la journée en indispo
        dayFullUnavailable(day) {
            day == 0 ? day = 7 : day = day
            this.$emit('addAllUnavailable', day)
        },
        //Event pour enlever toutes les indispo
        dayClearUnavailable(day) {
            day == 0 ? day = 7 : day = day
            this.$emit('removeAllUnavailable', day)
        },
        //Mise a jour de l'action de setting d'indispo via les events
        settingUnavailable(event, value) {
            this.isSettingUnavailable = value
        },
        //Event pour la suppression d'une indispo
        removeUnavailable(event, slot) {
            this.$emit('removeUnavailable', event, slot)
        },
        //event d'edition du rendez-vous
        editAppointement(event, id) {
            this.$emit('editAppointement', event, id)
        },
        //event de création du rendez-vous ou indispo
        addItem(event, itemData) {
            if (this.places && this.places.length > 0) {
                itemData.place = this.places.find((e) => e.value == this.place).dataPlace
            }

            this.$emit('addItem', event, itemData)
        },
        //event de création du rendez-vous ou indispo
        editItem(event, itemData) {
            this.$emit('editItem', event, itemData)
        },
        //Méthode pour générer le style (positionnement, taille, radius) des chips opérations
        getStyle(operation) {
            let css = "position:absolute; "
            const dayStart = operation.dates[0].split('/').join('');
            const offsetDays = this.dateArray.findIndex((e) => e == operation.dates[0])
            const indexOperation = this.operationsArray.findIndex((e) => e.id == operation.id);
            let top = indexOperation * 24 + indexOperation * 2
            css += "top:" + top + "px;"
            if (operation.duration < operation.fullDuration) {
                if (this.dateArray.includes(operation.start)) {
                    css += "border-radius: 12px 0 0 12px;";
                } else if (this.dateArray.includes(operation.end)) {
                    css += "border-radius: 0 12px 12px 0;";
                }
            }
            css += "left:" + (this.dayWidth * offsetDays + 1 * offsetDays) + "px; ";
            css += "width:" + (this.dayWidth * operation.duration) + "px";

            return css;
        },
        //méthode pour recupérer la couleur de la chips dépendant du statut du projet
        getColor(operation) {
            switch (operation.status) {
                case "QUOTE":
                    return "grey lighten-3"
                    break;
                case "SIGN_UP":
                    return "green lighten-3"
                    break;
                case "TO_REPORT":
                    return "orange darken-2"
                    break;
                case "IN_PROGRESS":
                    return "green darken-1"
                    break;
                case "CANCELLED":
                    return "red darken-3"
                    break;
                case "BILLED":
                    return "green darken-3"
                    break;
                case "ARCHIVED":
                    return "grey lighten-1"
                    break;
                default:
                    break;
            }
        },
        //page suivante
        nextPage() {
            this.firstDay = new Date(new Date(this.firstDay).getTime() + ((this.forcedDaysNumber == 1 ? 1 : 7) * 24 * 60 * 60 * 1000))
            this.$router.replace({ 'query': { 'from': this.firstDaySQL } })
            this.refreshStat();
        },
        //page précédente
        previousPage() {
            this.firstDay = new Date(new Date(this.firstDay).getTime() - ((this.forcedDaysNumber == 1 ? 1 : 7) * 24 * 60 * 60 * 1000))
            this.$router.replace({ 'query': { 'from': this.firstDaySQL } })
            this.refreshStat();
        },
        refreshStat() {
            if (this.operationId && this.place) {
                GenericDataService.getData('/operation/' + this.operationId + '/getTeamPlannings?withStats=1&place=' + this.place + '&from=' + this.firstDaySQL + '&to=' + this.parseFrDateToUs(this.dateArray[this.dateArray.length - 1])).then((response) => {
                    if (response && response.data && response.data.data && response.data.data.data) {
                        this.multi = response.data.data.data
                        this.multi.forEach((element, index) => {
                            element.color = this.default_colors[index]
                            element.border = this.default_borders[index]
                        });
                        this.appointements.forEach((element) => {
                            let user = this.multi.find((e) => e.id == element.ref_id)
                            element.ref = user
                        })
                        this.multiKey++
                    }
                })
            }
        },

        getHeightCalendar() {
            //get la hauteur du module chips d'opération
            let operationsHeight = this.operationsArray.length > 0 ? (this.operationsArray.length * 24 + (this.operationsArray.length) * 2) : 0;
            //calcul de la hauteur max du planning pour que tout soit dans une page
            this.firstDay = this.$route.query && this.$route.query.from ? new Date(this.$route.query.from) : this.startDate

            if (this.mode == "inner") {
                this.height = "max-height: calc(" + window.innerHeight + "px - 50px - " + operationsHeight + "px - 275px);"
            } else if (this.mode == "popup") {
                this.height = "max-height: calc(" + window.innerHeight + "px - 50px - " + operationsHeight + "px - 143px);"
            }
        },
    },
    created() {
        //a la création le firstday est égal au startday du planning.vue
        this.firstDay = this.$route.query && this.$route.query.from ? new Date(this.$route.query.from) : this.startDate

        if (this.vendeurId) {
            this.vendorSelected = this.vendeurId
        } else {
            this.vendorSelected = 'all'
        }
        if (this.vendorSelected != 'all') {
            this.filteredAppointements = this.appointements
        } else {
            this.filteredAppointements = this.appointements.filter((e) => e.ref_id == this.vendorSelected)
        }

        if (this.operationId) {
            GenericDataService.getData('/operation/' + this.operationId + '/getTeamPlannings?withStats=1&place=' + this.place + '&from=' + this.firstDaySQL + '&to=' + this.parseFrDateToUs(this.dateArray[this.dateArray.length - 1])).then((response) => {
                if (response && response.data && response.data.data && response.data.data.data) {
                    this.multi = response.data.data.data
                    this.multi.forEach((element, index) => {
                        element.color = this.default_colors[index]
                        element.border = this.default_borders[index]
                    });
                    this.appointements.forEach((element) => {
                        let user = this.multi.find((e) => e.id == element.ref_id)
                        element.ref = user
                    })
                }
                GenericDataService.getData('/operation/' + this.operationId + '/getPlacesWithVendors').then((response) => {
                    if (this.superlead && this.superlead.entity_id && this.superlead.entity_id.id) {
                        this.places = response.data.data.filter((e) => e.dataPlace && e.dataPlace.config && e.dataPlace.config.entity_id && e.dataPlace.config.entity_id.includes(this.superlead.entity_id.id))
                        this.place = this.places[0].value
                    } else {
                        this.places = response.data.data
                        if (this.$func.hasRight('profile/is_vendor') && !this.$func.hasRight('profile/is_manager')) {
                            let placeVendor = this.places.find((e) => e.users.includes(this.$store.state.auth.user.id))
                            if (placeVendor)
                                this.place = placeVendor.value
                            else
                                this.place = this.places[0].value
                        } else if (this.appointements.length == 1) {
                            this.place = this.appointements[0].place.id
                        } else if (this.multi && this.vendeurId && this.vendeurId != 'all') {
                            this.place = this.multi.find((e) => e.id == this.vendeurId).places[0].id
                        } else {
                            this.place = this.places[0].value
                        }
                    }
                    if (this.vendorSelected == 'all')
                        this.selectVendor('all')
                    else
                        this.selectVendor(this.vendorSelected)
                })

            })
        }
        //timeout pour scroll le planning a l'heure définie dans la data moveTo
        setTimeout(() => {
            this.$refs.inner.scrollTo(0, this.moveTo * 4 * 15);
            this.refreshStat();
        }, 250)

    },
    mounted() {
        //get la hauteur du module chips d'opération
        let operationsHeight = this.operationsArray.length > 0 ? (this.operationsArray.length * 24 + (this.operationsArray.length) * 2) : 0;
        //calcul de la hauteur max du planning pour que tout soit dans une page
        this.firstDay = this.$route.query && this.$route.query.from ? new Date(this.$route.query.from) : this.startDate

        if (this.mode == "inner") {
            this.height = "max-height: calc(" + window.innerHeight + "px - 50px - " + operationsHeight + "px - 275px);"
        } else if (this.mode == "popup") {
            this.height = "max-height: calc(" + window.innerHeight + "px - 50px - " + operationsHeight + "px - 143px);"
        }

        if (this.operationId) {
            GenericDataService.getData('/operation/' + this.operationId + '/getTeamPlannings?withStats=1&place=' + this.place + '&from=' + this.firstDaySQL + '&to=' + this.parseFrDateToUs(this.dateArray[this.dateArray.length - 1])).then((response) => {
                if (response && response.data && response.data.data && response.data.data.data) {
                    this.multi = response.data.data.data
                    this.multi.forEach((element, index) => {
                        element.color = this.default_colors[index]
                        element.border = this.default_borders[index]
                    });
                    this.appointements.forEach((element) => {
                        let user = this.multi.find((e) => e.id == element.ref_id)
                        element.ref = user
                    })
                }
                GenericDataService.getData('/operation/' + this.operationId + '/getPlacesWithVendors').then((response) => {
                    if (this.superlead && this.superlead.entity_id && this.superlead.entity_id.id) {
                        this.places = response.data.data.filter((e) => e.dataPlace && e.dataPlace.config && e.dataPlace.config.entity_id && e.dataPlace.config.entity_id.includes(this.superlead.entity_id.id))
                        this.place = this.places[0].value
                    } else {
                        this.places = response.data.data
                        if (this.$func.hasRight('profile/is_vendor') && !this.$func.hasRight('profile/is_manager')) {
                            let placeVendor = this.places.find((e) => e.users.includes(this.$store.state.auth.user.id))
                            if (placeVendor)
                                this.place = placeVendor.value
                            else
                                this.place = this.places[0].value
                        } else if (this.appointements.length == 1) {
                            this.place = this.appointements[0].place.id
                        } else if (this.multi && this.vendeurId && this.vendeurId != 'all') {
                            this.place = this.multi.find((e) => e.id == this.vendeurId).places[0].id
                        } else {
                            this.place = this.places[0].value
                        }
                    }
                    if (this.vendorSelected == 'all')
                        this.selectVendor('all')
                    else
                        this.selectVendor(this.vendorSelected)
                })
            })

        }

        //set la largeur d'un jour pour s'en servir dans la fonction de gestion du style des chips
        this.dayWidth = this.$refs.day[0].$el.offsetWidth;
        //timeout pour scroll le planning a l'heure définie dans la data moveTo
        setTimeout(() => {
            this.$refs.inner.scrollTo(0, this.moveTo * 4 * 15);
            this.refreshStat();
        }, 250)


        const socketServer = new WebSocket(process.env.VUE_APP_WEBSOCKET_URL);
        this.socketServer = socketServer;
        socketServer.onopen = (e) => {
            socketServer.send(JSON.stringify({
                type: 'subscribe',
                account: this.$store.state.auth.currentAccountId,
                operation: this.operationId
            }));
        };
        socketServer.onmessage = (e) => {
            let data = JSON.parse(e.data);
            if (data.type == 'refresh_rdv') {
                this.selectVendor(this.vendorSelected)
            }
        };
    },
    watch: {
        place(val) {
            let placeItem = this.places.find((e) => e.value == val)
            if (this.type == "EDIT_RDV_DATE_REQUEST" || this.type == "EDIT_RDV_REQUEST") {
                if (this.appointements && this.appointements.length > 0 && this.appointements[0].ref_id && !placeItem.users.includes(this.appointements[0].ref_id.toString())) {
                    this.selectVendor('all')
                }
            } else if (this.type == "ADD_RDV_REQUEST") {
                if (!placeItem.users.includes(this.vendeurId)) {
                    if (!placeItem.users.includes(this.$store.state.auth.currentUser.profile.id)) {
                        this.selectVendor('all')
                    } else {
                        this.selectVendor(this.$store.state.auth.currentUser.profile.id)
                    }
                } else {
                    this.selectVendor(this.vendeurId)
                }
            } else if (this.type == "DISPLAY_OPERATION_RDV") {
                this.selectVendor('all')
            }
        },
        startDate(val) {
            this.firstDay = val
        },
        firstDaySQL: function (newDate) {
            this.refreshStat();
            if (this.type != "EDIT_RDV_DATE_REQUEST" && this.type != "EDIT_RDV_REQUEST" && this.type != "ADD_RDV_REQUEST" && this.type != "DISPLAY_OPERATION_RDV") {
                this.$emit('changeDate', new Date(newDate))
                //this.selectVendor(this.vendorSelected)
            } else if (this.type == "DISPLAY_OPERATION_RDV") {
                this.selectVendor(this.vendorSelected)
            } else if (this.type == "EDIT_RDV_DATE_REQUEST") {
                this.selectVendor(this.vendorSelected)
            } else {
                this.selectVendor(this.vendeurId)
            }
        },

        filteredAppointements(val) {
            //this.planningDayKey++
        },
        appointements: {
            deep: true,
            // We have to move our method to a handler field
            handler() {
                if (this.vendorSelected == "all") {
                    this.filteredAppointements = this.appointements
                } else {
                    this.filteredAppointements = this.appointements.filter((e) => e.links && e.links.user && e.links.user[0] == this.vendorSelected)
                }
            }
        },
        forcedDaysNumber(val) {
            this.refreshStat();
        },
    },
    destroyed() {
        window.removeEventListener("resize", this.getHeightCalendar);
    },
}
</script>
<style lang="scss">
.blueNumber {
    font-weight: bold;
    color: #155e96;
}

.yellowNumber {
    font-weight: bold;
    color: #d89c33;
}

.textfieldDate {
    .v-text-field__slot {
        input {
            margin-top: 0 !important;
            max-height: 52px !important;
        }
    }
}

.multiMenu {
    width: 20%;

}

.vendorItem {
    cursor: pointer;
    padding: 20px;
    border: 1px solid #FFF;
    border-left: 8px solid rgba(0, 0, 0, 0.4);
    background-color: #fff;
    display: flex;
    align-items: center;
    height: 100px;

    &.selected {
        border-left: 8px solid var(--v-primary-base);
        background-color: #FFF;
    }

    .annotation {
        color: #989898;
        font-size: 11px;
        margin-bottom: 2px;
    }

    .name {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        font-weight: bold;
        color: #676767;
    }

    .pastille {
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
    }
}

.weekDates {
    font-size: 14px;

    @media (-webkit-device-pixel-ratio: 1.25) {
        font-size: 0.9vw;
    }
}

.chipsCol {
    position: relative;
}

.contentCalendar {
    width: 100%;
}

.legendCalendar {
    width: 50px;
    padding: 12px;
}

.iconsCalendar {
    width: 5%;
    justify-content: center;
    display: flex;
    align-items: center;
}

.headingFilters {
    z-index: 20;

    &.multi {

        //border-bottom: 1px solid rgba(0,0,0,0.2);
    }
}

.headingPlanning {
    // border-top:1px solid rgba(0,0,0,0.2);
    // border-left:1px solid rgba(0,0,0,0.2);
}

.innerCalendar {
    display: flex;
    position: relative;
    width: 100%;
    //border:1px solid rgba(0,0,0,0.2)

}

.daysRowCalendar {
    overflow-y:auto;
    position: relative;
}

.multiMenuContent {
    position: absolute;
    background-color: #f5f5f5;
    z-index: 6;
    left: 0;
    top: 60px;
    width: 19%;
    height: calc(100% - 60px);
    //border-right:1px solid rgba(0,0,0,0.2);
}

.colDays {
    border-left: 1px solid #EEE;
}

.scrollOffset {
    width: 17px;
}

.v-card--link:focus {
    background: #FFF;
}

.v-card--link:focus::before {
    opacity: 0;
}
</style>