<template>
    <div>
        <PlanningCalendar 
            :startDate="startDayReal" 
            :daysNumber="daysNumber" 
            :type="type"
            :isMine="isMine"
            :creneau="creneau"
            :operations="operations"
            :appointements="appointements"
            :operationId="operationId"
            :genericUnavailable="genericUnavailable"
            :mode="mode"
            :vendeurId="vendeurId"
            :key="'planningCalendar_'+planningCalendarKey"
            :popup="popup"
            :contact="contact"
            :superlead="superlead"
            @vendorSelect="vendorChanged"
            @removeAllUnavailable="removeAllUnavailable"
            @addAllUnavailable="addAllUnavailable"
            @addItem="addItem"
            @editItem="editItem"
            @editAppointement="editAppointement"
            @removeUnavailable="removeUnavailable"
            @changeDate="changeDate"
            @deleteUserIndispo="deleteUserIndispo"
            @recordInfos="recordInfos"
            />
        <!-- <PlanningAppointementModal :openModal="openModal" :modalMode="modalMode" :appointementData="appointementData" 
            @closeModal='closeModal'
            @registerRdv="registerRdv"
            @deleteRdv="deleteRdv"
            /> -->
    </div>
</template>

<script>
import PlanningCalendar from './PlanningComponents/PlanningCalendar'
import PlanningAppointementModal from './PlanningComponents/PlanningAppointementModal'
import dateManipulation from '@/mixins/dateManipulation'
import planningMixins from '@/mixins/planningMixins'
import GenericDataService from '@/services/GenericDataService'

export default {
    name:"Planning",
    props:["type", "vendeurId", "entityId", "isMine", "operationId", "contact", "creneau", "operations", "appointements", "mode", "genericUnavailable", 'popup', "superlead"],
    mixins:[dateManipulation, planningMixins],
    components: {
        PlanningCalendar, PlanningAppointementModal
    },
    data() {
        return {
            openModal:false, 
            modalMode:"",
            appointementData: {},
            places: [],
            planningCalendarKey:0,
            startDayReal: ""
        }
    },
    created() {
        //if(this.type == "PLANNING" || this.type == "INDISPO_USER"){
            //setTimeout(() => {
                this.startDayReal = this.startDay
            //}, 200);
            
        //}
        if((this.type == "PLANNING" || this.type == "INDISPO_USER" || this.type == "INDISPO_COACH")){
             let payload = {
                from: this.parseJsDateToUs(this.startDay),
                nbdays: this.daysNumber,
                entity_id: this.entityId,
                test:"Mounted"
            }
            this.$emit('getPlanningIndispo', payload)
        }

    },
    mounted() {
        // setTimeout(() => {
                this.startDayReal = this.startDay
        //    }, 200);
        if((this.type == "PLANNING" || this.type == "INDISPO_USER" || this.type == "INDISPO_COACH")){
            
            let payload = {
                from: this.parseJsDateToUs(this.startDay),
                nbdays: this.daysNumber,
                entity_id: this.entityId,
                test:"Mounted"
            }
            this.$emit('getPlanningIndispo', payload)
        } else if (this.type == "INDISPO_CONCESSION") {
            // GenericDataService.getData('/entity/'+this.$store.state.auth.currentUser.profile.entity_id+"/get").then((response) => {
            //     this.genericUnavailable = response.data.data.hours
            // })
        }
    },
    watch: {
        vendeurId(val){
            this.planningCalendarKey++
        },
        startDay: function(val, oldVal){
            if((this.parseJsDateToUs(val) != this.parseJsDateToUs(oldVal))){
                let payload = {
                    from: this.parseJsDateToUs(val),
                    nbdays: this.daysNumber,
                    entity_id: this.entityId
                }
                this.$emit('getPlanningIndispo', payload)
                this.startDayReal = val
            }
            
        }
    },
    computed: {
        //Calcul du nombre de jours a afficher dépendant du type de planning 
        daysNumber: function(){
            if((this.type == 'OPERATION' || this.type == 'OPERATION_HOURS' || this.type =="DISPLAY_OPERATION_RDV" || this.type =="ADD_RDV_REQUEST" || this.type =="EDIT_RDV_DATE_REQUEST" || this.type =="EDIT_RDV_REQUEST" || this.type =="INDISPO_USER" || this.type == "INDISPO_COACH") && this.operations.length > 0 && this.operations[0].config.date_start  && this.operations[0].config.date_end){
                let operation = this.operations.find((e) => e.id == this.operationId)
                let startArray = operation.config.date_start.split(' ')
                let endArray = operation.config.date_end.split(' ')
                let days = this.DaysBetween(this.parseDate(startArray[0]), this.parseDate(endArray[0]))
                if(this.$func.hasRight('lead/update_duration'))
                    days = 8
                return (days > 7) ? 7 : days
            } else {
                return 7
            }
        },
        //Détermine le jour du début du calendrier dépendant du type du planning
        startDay: function(){
            let today = new Date;
            if((this.type =="INDISPO_USER" || this.type == "INDISPO_COACH") && this.$store.state.agenda.date_start){ //pour initialiser la popup avec la bonne date d'insdispo cliquer sur l'agenda de la home
                return (this.daysNumber >= 7) ? new Date( this.parseDate(this.$store.state.agenda.date_start) - (( this.parseDate(this.$store.state.agenda.date_start).getDay() == 0 ? 7 : this.parseDate(this.$store.state.agenda.date_start).getDay() ) -1)  *24*60*60*1000  ) : this.parseDate(this.$store.state.agenda.date_start)
            } else if((this.type == 'OPERATION' || this.type =="ADD_RDV_REQUEST" || this.type =="DISPLAY_OPERATION_RDV" || this.type =="EDIT_RDV_DATE_REQUEST" || this.type =="EDIT_RDV_REQUEST" || this.type =="INDISPO_USER" || this.type == "INDISPO_COACH") && this.operations.length > 0 && this.operations[0].config.date_start  && this.operations[0].config.date_end){
                let operation = this.operations.find((e) => e.id == this.operationId)
                let startArray = operation.config.date_start.split(' ')
                return (this.daysNumber >= 7) ? new Date( this.parseDate(startArray[0]) - (( this.parseDate(startArray[0]).getDay() == 0 ? 7 : this.parseDate(startArray[0]).getDay() ) -1)  *24*60*60*1000  ) : this.parseDate(startArray[0])
            } else if (this.type =="EDIT_RDV_DATE_REQUEST"){
                //let operation = this.operations.find((e) => e.id == this.operationId)
                let startArray = this.appointements.length > 0 ? this.appointements[0].date : null
                if(this.appointements.length > 0)
                    return (this.daysNumber >= 7) ? new Date( this.parseDate(this.appointements[0].date) - (( this.parseDate(this.appointements[0].date).getDay() == 0 ? 0 : this.parseDate(this.appointements[0].date).getDay() ) -1)  *24*60*60*1000  )  : this.parseDate(startArray)
                else    
                    return (this.daysNumber >= 7) ? new Date(( today - (today.getDay() -1)*24*60*60*1000 )) : this.parseDate(startArray[0])
            } else if(this.type == 'OPERATION_HOURS' ) {
                let operation = this.operations.find((e) => e.id == this.operationId);
                let startArray = null;
                if(operation.config.date_start) {
                    startArray = operation.config.date_start.split(' ')
                }
               return (this.daysNumber >= 7) || startArray === null ? new Date(( today - (( today.getDay() == 0 ? 7 : today.getDay() ) -1)*24*60*60*1000 ) + 7*24*60*60*1000) : this.parseDate(startArray[0])
            
            } else {
                return new Date( today - (( today.getDay() == 0 ? 7 : today.getDay() ) -1)*24*60*60*1000 )
            }
        },
        //Création d'un array de date dépendant de la date du début et le nombre de jours à afficher
        dateArray: function() {
            const array = [];
            for (let index = 0; index < this.daysNumber; index++) {
                let element = new Date (new Date(this.startDay).getTime() + (index*24*60*60*1000)) ;
                array.push(new Intl.DateTimeFormat('fr-FR').format(element))
            }
            return array
        }, 
    },
    methods: {
        recordInfos(infos){
            this.$emit('recordInfos', infos)
        },
        deleteUserIndispo(slot){
            if(this.type == "INDISPO_USER"  || this.type == "INDISPO_COACH"){
               this.$emit('deleteUserIndispo', slot)
            }
        },
        changeDate(newStartDate){
            if(this.type != "EDIT_RDV_DATE_REQUEST" && this.type !="EDIT_RDV_REQUEST" && this.type !="ADD_RDV_REQUEST"){
                this.startDayReal = new Date(newStartDate)
                let payload = {
                    from: this.parseJsDateToUs(newStartDate),
                    nbdays: this.daysNumber,
                    entity_id: this.entityId,
                    test: 'changeDate'
                }
                this.$emit('getPlanningIndispo', payload)
            }
        },
        vendorChanged(vendorIds, startDate, placeId){
            if(this.type == "EDIT_RDV_DATE_REQUEST" || this.type =="EDIT_RDV_REQUEST" || this.type =="ADD_RDV_REQUEST"){
                let payload = {
                    from: this.parseJsDateToUs(startDate),
                    nbdays: this.daysNumber,
                    user_ids : vendorIds,
                    test: 'vendorChanged'
                }
                if(this.type=="EDIT_RDV_DATE_REQUEST" || this.type=="EDIT_RDV_REQUEST") {
                    if(this.appointements && this.appointements.length > 0){
                        payload.rdv_id = parseInt(this.appointements[0].id)
                    }
                }
                this.$emit('getPlanningIndispo', payload)
            } else if (this.type == "DISPLAY_OPERATION_RDV" ){
                let payload = {
                    from: this.parseJsDateToUs(startDate),
                    nbdays: this.daysNumber,
                    user_ids : vendorIds,
                    entity_id: this.entityId,
                    place : placeId,
                    test: 'vendorChanged2'
                }
                this.$emit('getPlanningIndispo', payload)
                this.$emit('refreshRdvList', vendorIds)
            }
        },
        addAllUnavailable(day){
            this.$emit('addAllUnavailable', day)
        },
        removeAllUnavailable(day){
            this.$emit('removeAllUnavailable', day)
        },
        //fermeture de modale
        // closeModal(){
        //     this.openModal = false
        // },
        //event de suppression d'indispo
        removeUnavailable(event, slot){
            this.$emit('removeUnavailable', event, slot)
        },
        //Ouverture de la popup pour ajouter un rendez vous
        editAppointement(event, id){
            let appointement = this.appointements.find((e) => e.id == id)
            // this.openModal = true
            // this.modalMode = "Edit"
            Object.assign(this.appointementData, appointement)
        },
        //Ouverture de la popup pour ajouter un rendez vous
        addItem(event, itemData){
            if(this.type != "INDISPO_CONCESSION" && this.type != "INDISPO_USER" && this.type != 'OPERATION_HOURS' && this.type != "INDISPO_COACH"){
                //Object.assign(this.appointementData, itemData)
                this.$emit('addRdv', itemData)
                // this.openModal = true
                // this.modalMode = "Add"
                
            } else {
                this.$emit('addItem', event, itemData)
            }
        },
        editItem(event, itemData){
            this.$emit('editItem', event, itemData)
        },
        //Fonction pour savoir si on peut drag le rdv a ce point
        rdvIsDraggableHere(dateFr, numericStart, numericEnd, numericCreneau, rdvId){
            let response = true;
            let operations = this.getOperationsArray(this.operations, this.dateArray)
            let unavailables = this.getUnavailableSlots(dateFr, operations, this.genericUnavailable, this.type)
            unavailables.forEach(unavailable => {
                if(!unavailable.id || unavailable.id != rdvId){
                    let unavailableStart = this.parseHourToFloat(unavailable.start)
                    let unavailableEnd = this.parseHourToFloat(unavailable.end)
                    unavailableEnd == 0 ? unavailableEnd = 24 : unavailableEnd = unavailableEnd
                    if((numericStart > (unavailableStart-numericCreneau) && numericStart < unavailableEnd) 
                    || (numericEnd > unavailableStart && numericEnd <= unavailableEnd)){
                        response = false;
                    }
                }
            });
            return response
        },
        //fonction d'enregistrement d'un rendez-vous
        registerRdv(event, rdv){
            this.appointements.push(rdv);
            this.openModal = false
        },
        //fonction d'effacement d'un rendez-vous
        deleteRdv(event, id){
            let rdvIndex = this.appointements.findIndex((e) => e.id == id)
            this.appointements.splice(rdvIndex, 1)
            this.openModal = false
        }
    },
}

</script>