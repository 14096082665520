<template>
    <div :class="mode == 'temp' ? 'tempRdv rdv rdv_'+infos.min_start+'_'+infos.col : 'rdv rdv_'+infos.min_start+'_'+infos.col" :style="getStyleRdv">
        <div class="previousImpossibility" v-show="hasPreviousImpossibility"  :style="stylePreviousImpossibility">
        </div>
        <div class="inner-rdv" :style="setSize()" @click="type == 'DISPLAY_OPERATION_RDV' && infos.links_hydrated && infos.links_hydrated.contact && infos.links_hydrated.contact[0] ? openSuperlead() : ''">
            
            <v-row dense v-if="mode != 'real'" class="ml-2"  @click="openDetailRdv">
                <v-col cols="10">
                    <div>{{infos.start}} - {{infos.end}}</div>
                    <span style="font-weight:normal" v-if="infos.links_hydrated && infos.links_hydrated.contact && infos.links_hydrated.contact[0]">{{infos.links_hydrated.contact[0].title}}</span>
                </v-col>
                <v-col cols="2" v-if="infos.status == 'FINISHED'" class="d-flex align-center justify-end">
                    <v-icon color="white" class="mr-4">{{ infos.is_success == "1" ? '$check_s' : '$delete' }}</v-icon>
                </v-col>
            </v-row>
            <v-tooltip right v-else content-class="rdv--tooltip" max-width="400px">
                <template v-slot:activator="{ on, attrs }">
                    <v-row dense class="ml-2" v-bind="attrs" v-on="on"  @click="openDetailRdv">
                        <v-col cols="10">
                            <div>{{infos.start}} - {{infos.end}}</div>
                            <span style="font-weight:normal" v-if="infos.links_hydrated && infos.links_hydrated.contact && infos.links_hydrated.contact[0]">{{infos.links_hydrated.contact[0].title}}</span>
                        </v-col>
                        <v-col cols="2" v-if="infos.status == 'FINISHED'" class="d-flex align-center justify-end">
                            <v-icon color="white" class="mr-4">{{ infos.is_success == "1" ? '$check_s' : '$delete' }}</v-icon>
                        </v-col>
                    </v-row>
                </template>
                <span>
                    <div class="border-vendor" :style="'background-color:'+vendorColor"></div>
                    <div class="d-flex align-start pl-2 py-2">
                        <div>
                            <div class="d-flex">
                                <v-icon small class="mr-2">$clock_d</v-icon>
                                <div>
                                    <b style="font-size:12px;">{{ capitalizeFirstLetter(timestampToDate(new Date(infos.date_start.split(' ')[0]).getTime() / 1000, 'dayMonthYear3')) }}</b>
                                    <div style="font-size:12px;">{{infos.start}} - {{infos.end}}</div>
                                </div>
                            </div>
                            <div class=" mt-2 d-flex">
                                <v-icon small class="mr-2">$usertie_d</v-icon>
                                <div>
                                    <div style="font-size:12px;" v-if="infos.links_hydrated && infos.links_hydrated.user && infos.links_hydrated.user[0]"><b>{{infos.links_hydrated.user[0].title}}</b></div>
                                    <div style="font-size:12px;" v-else>{{ $t('novendorselected')}}</div>
                                </div>
                            </div>
                            <div class=" mt-2 d-flex">
                                <v-icon small class="mr-2">$user_d</v-icon>
                                <div>
                                    <div style="font-size:12px;" v-if="infos.links_hydrated">{{infos.links_hydrated.contact[0].title}}</div>
                                </div>
                            </div>
                        </div>
                        <v-icon  v-if="infos.status == 'FINISHED'" :color="vendorColor">{{ infos.is_success == "1" ? '$check_s' : '$delete' }}</v-icon>
                    </div>
                </span>
            </v-tooltip>
        </div>
        <div class="nextImpossibility" v-show="hasNextImpossibility"  :style="styleNextImpossibility">
        </div>
    </div>
</template>
<script>
import DonutCSS from '@/components/DonutCSS'
import dateManipulation from '@/mixins/dateManipulation'
import planningMixins from '@/mixins/planningMixins'
import SelectVendorRdvDialogComponent from '@/components/dialogs/SelectVendorRdvDialogComponent'
import SelectContactRdvDialogComponent from '@/components/dialogs/SelectContactRdvDialogComponent'
import SelectRessourceRdvDialogComponent from '@/components/dialogs/SelectRessourceRdvDialogComponent'
import GenericDataService from '@/services/GenericDataService'
export default {
    name:"PlanningRdv",
    props:['creneau', 'infos', 'mode', 'unavailables', 'multi', 'appointementsArray', 'places', 'rdvIsEdited', 'type'],
    mixins:[dateManipulation, planningMixins],
    components: {DonutCSS, SelectContactRdvDialogComponent, SelectRessourceRdvDialogComponent, SelectVendorRdvDialogComponent},
    data() {
        return {
            publicPath: process.env.BASE_URL,
            impossibilityBottomSize: 0,
            impossibilityTopSize: 0, 
            shown: false,
            visioUnavailable: false,
            displayVisioSelect: false,
            displayCreneauSelect:false,
            creneauUnavailable: false,
            placeUnavailable: false,
            displayPlaceSelect: false,
            displaySelectVendor: false,
            rdvInfo: {},
            displaySelectRessources: false,
            openSelectContactDialog: false,
            closeOnClick:true,
            zindex:5,
            demandSelected: []
        }
    },
    created() {
        this.rdvInfo = this._.cloneDeep(this.infos)
        if(this.rdvInfo.demands && this.rdvInfo.demands.length > 0){
            this.rdvInfo.demands.forEach((element) => {
                GenericDataService.getData('/demand/'+element.id+'/_get').then((response) => {
                    this.demandSelected.push(response.data.data)
                })
            })
        }
    },
    watch: {
        infos(val){
            this.rdvInfo = this._.cloneDeep(val)
        },
        displayVisioSelect(val) {
            if(val){
                this.$nextTick(() => {
                    this.$refs.visioSelectRef.focus();
                    this.$refs.visioSelectRef.activateMenu()
                })
                
            }
        },
        displayCreneauSelect(val) {
            if(val){
                this.$nextTick(() => {
                    this.$refs.visioCreneauRef.focus();
                    this.$refs.visioCreneauRef.activateMenu()
                })
            }
        },
    },
    methods: {
        capitalizeFirstLetter(string) {
            return string[0].toUpperCase() + string.slice(1);
        },
        openSuperlead() {
            GenericDataService.getData("superlead/getByRdvId?id=" + this.infos.id + ('&operation_id=' + (this.infos.operation_id.id ? this.infos.operation_id.id : this.infos.operation_id))).then((response)=> {
                this.$store.dispatch('lead/SET_SUPERLEAD_AND_OPEN_DIALOG', response.data.data)
            });
        },
        getLink(link){
            this.$router.push(link)
        },
        clickOutsideRdv() {
        },
        openDetailRdv() {
            this.zindex = 6
        },
        closeVendorDialog() {
            this.displaySelectVendor = false
        },
        selectRessource(idRessource){
            this.rdvInfo.ressource = idRessource
            this.closeRessourceDialog();
        },
        closeRessourceDialog() {
            this.displaySelectRessources = false
        },
        closeSelectContactDialog(){
            this.openSelectContactDialog = false
        },
        recordInfos(){
            this.$emit('recordInfos', this.rdvInfo)
            this.$emit("editRdv", false)
            this.shown = false
        },
        confirmVendorSelected(selectedVendor) {
            if(selectedVendor != ""){
                let vendor = this._.cloneDeep(this.multi.find((e) => e.id == selectedVendor))
                this.rdvInfo.ref_id = selectedVendor
                this.rdvInfo.ref = vendor
                this.displaySelectVendor = false
            }
        },
        
        selectOrEditVendor() {
            this.displaySelectVendor = true
        },
        selectOrEditRessource() {
            this.displaySelectRessources = true
        },
        closeDetails(){
            this.shown = false
            this.$emit("editRdv", false)
            this.$refs.testMenu.save()
        },
        visioUnavailableFct(){
            if(!this.infos.ref || !this.infos.ref.visioAvailable){
                this.visioUnavailable = true;
                setTimeout(() => {
                    this.visioUnavailable = false
                }, 2000);
            } else {
                this.displayVisioSelect = true
            }
        }, 
        closeVisio(){
            if(this.displayVisioSelect){
                this.displayVisioSelect = false
            }
        },
        closeCreneau(){
            if(this.displayCreneauSelect){
                this.displayCreneauSelect = false
            }
        },
        CreneauFct(){
            if(!this.infos || !this.infos.min_duration){
                this.creneauUnavailable = true;
                setTimeout(() => {
                    this.creneauUnavailable = false
                }, 2000);
            } else {
                this.displayCreneauSelect = true
            }
        },
        placeFct() {
            if(!this.infos || !this.infos.place || this.places.length == 1){
                this.placeUnavailable = true;
                setTimeout(() => {
                    this.placeUnavailable = false
                }, 2000);
            } else {
                this.displayPlaceSelect = true
            }
        },
        closePlace() {
            if(this.displayPlaceSelect){
                this.displayPlaceSelect = false
            }
        },
        //set la hauteur du rendez vous dépendant du creneau
        setSize: function (){
            if(this.infos && this.infos.links && this.infos.links.user && this.infos.links.user[0]){
                let vendorId = this.infos.links.user[0]
                let vendorInfo = this.multi.find((e) => e.id == vendorId)
                if (!vendorInfo) {
                    return "height:"+(this.parseHourToFloat(this.infos.end) - this.parseHourToFloat(this.infos.start))*60+"px; color:white;";
                }
                
                if(this.infos.status == "CONFIRMED"){
                    return "height:"+(this.parseHourToFloat(this.infos.end) - this.parseHourToFloat(this.infos.start))*60+"px; background-color:"+vendorInfo.color+"; border:1px solid "+vendorInfo.border+"; color:white;";
                } else if (this.infos.status == "FINISHED"){
                    return "height:"+(this.parseHourToFloat(this.infos.end) - this.parseHourToFloat(this.infos.start))*60+"px; background-color:"+vendorInfo.color+"; border:1px solid "+vendorInfo.border+"; color:white;";
                } else {
                    return "height:"+(this.parseHourToFloat(this.infos.end) - this.parseHourToFloat(this.infos.start))*60+"px; background-color:white; border:1px solid "+vendorInfo.border+"; color:"+vendorInfo.color+";";
                }
            } else {
                return "height:"+(this.parseHourToFloat(this.infos.end) - this.parseHourToFloat(this.infos.start))*60+"px; color:white;";
            }
        }
    },
    computed: {
        vendorColor() {
            if(this.infos && this.infos.links && this.infos.links.user && this.infos.links.user[0]){
                let vendorId = this.infos.links.user[0]
                let vendorInfo = this.multi.find((e) => e.id == vendorId)
                if (vendorInfo) {
                    return vendorInfo.color
                }
            }
            return "black"
        },
         getStyleRdv() {
            let css = "z-index:"+this.zindex+";"
            if (this.type == 'EDIT_RDV_REQUEST' || this.type=='EDIT_RDV_DATE_REQUEST'){
                css += ' pointer-events:none;'
            }
            return css
        },
        //style du bloc creneau trop court pour un rdv précédent
        stylePreviousImpossibility: function(){
            return "height:"+this.impossibilityTopSize+"px; top:-"+this.impossibilityTopSize+"px;";
        },
        //fonction pour savoir si on pourra mettre un rdv avant celui que l'on veut poser
        hasPreviousImpossibility: function(){
            let response = false;
            this.impossibilityTopSize = 0;
            if( this.unavailables.length > 0 && this.infos.start != 0){
                this.unavailables.forEach(element => {
                    let numericEnd = this.parseHourToFloat(element.end)
                    let tempStart = this.parseHourToFloat(this.infos.start)
                    if((tempStart-numericEnd) < (this.creneau / 60) && tempStart-numericEnd > 0){
                        let height = (tempStart-numericEnd)*60;
                        this.impossibilityTopSize = height;
                        response = true
                    }
                    if(response && this.appointementsArray) {
                        this.appointementsArray.forEach(elementRdv => {
                            let startTocheck = tempStart - 1;
                            let endToCheck = tempStart;
                            if(this.parseHourToFloat(elementRdv.start) >= startTocheck && this.parseHourToFloat(elementRdv.end) <= endToCheck)
                                response = false
                        })
                    }
                });
            } 
            if(!this.multi && this.appointementsArray.length >0){
                this.appointementsArray.forEach(element => {
                    let numericEnd = this.parseHourToFloat(element.end)
                    let tempStart = this.parseHourToFloat(this.infos.start)
                    if((tempStart-numericEnd) < (this.creneau / 60) && tempStart-numericEnd > 0){
                        let height = (tempStart-numericEnd)*60;
                        this.impossibilityTopSize = height;
                        response = true
                    }
                });
            }
            return response
        },
        //style du bloc creneau trop court pour un rdv suivant
        styleNextImpossibility: function(){
            return "height:"+this.impossibilityBottomSize+"px";
        },
        //fonction pour savoir si on pourra mettre un rdv après celui que l'on veut poser
        hasNextImpossibility: function(){
            let response = false;
            this.impossibilityBottomSize = 0;
            if(this.unavailables.length > 0 && this.infos.start != 0){
                this.unavailables.forEach(element => {
                    let numericStart = this.parseHourToFloat(element.start)
                    let tempEnd = this.parseHourToFloat(this.infos.end)
                    if((numericStart-tempEnd) < (this.creneau / 60) && numericStart-tempEnd > 0){
                        let height = (numericStart-tempEnd)*60;
                        this.impossibilityBottomSize = height;
                        response = true
                    }
                });
            } 
            if(!this.multi && this.appointementsArray.length >0){
                this.appointementsArray.forEach(element => {
                    let numericStart = this.parseHourToFloat(element.start)
                    let tempEnd = this.parseHourToFloat(this.infos.end)
                    if((numericStart-tempEnd) < (this.creneau / 60) && numericStart-tempEnd > 0){
                        let height = (numericStart-tempEnd)*60;
                        this.impossibilityBottomSize = height;
                        response = true
                    }
                });
            }
            return response
        },
        
    },
}
</script>
<style lang="scss">
.rdv--tooltip {
    opacity: 1 !important;
    background-color: white;
    color: black;
    box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.11) !important;
    overflow:hidden;
    .border-vendor {
        width:10px;
        height: 100%;
        position:absolute;
        top:0;
        left:0;
    }
}
.ressourceLine {
    display:flex;
    align-items: center;
    justify-content: space-between;
}
.vendorItems {
    display : flex;
    justify-content: space-between;
}
.rdv {
    .v-menu__content {
        max-width: unset;
    }
}
.ressourceSelectorItem {
    cursor:pointer;
    position:relative;
    padding:15px;
    border: 1px solid rgba(0,0,0,0.2);
    margin-top:15px;
    width: 100%;
    &.selected {
        border: 1px solid var(--v-success-base);
        background-color: #FFF;
    }
    .selected {
        top:0;
        left:0;
        z-index:11;
        position:absolute;
        width:100%;
        height:100%;
        background-color:rgba(91,184,93,0.2)
    }
    &.unavailableItem{
        opacity:0.7;
        pointer-events: none;
        border: 1px solid rgba(0,0,0,0.1);
        background-color: rgba(0,0,0,0.2);
        .name {
            text-decoration-line: line-through;
        }
    }
}
.vendorSelectorItem {
        cursor:pointer;
        position:relative;
        padding:20px;
        border: 1px solid rgba(0,0,0,0.2);
        margin-top:20px;
        width: 32%;
        background-color: #FFF;
        display:flex;
        align-items: center;
        height:100px;
        &.selected {
           border: 1px solid var(--v-success-base);
           background-color: #FFF;
        }
        .selected {
            top:0;
            left:0;
            z-index:11;
            position:absolute;
            width:100%;
            height:100%;
            background-color:rgba(91,184,93,0.2)
        }
        .annotation {
            color:#989898;
            font-size:11px;
            margin-bottom:2px;
        }
        .name {
            display:flex;
            align-items: center;
            text-transform: uppercase;
            font-weight:bold;
            color:#676767;
        }
        .pastille {
            display:block;
            width:10px;
            height:10px;
            border-radius: 50%;
        }
    }
.labelSelect {
    text-transform: none;
    &.demandsDisplay {
        display:flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
}
.rdvDetail {
    .v-btn {
        height:30px !important;
    }
    .adressBtn {
        height:auto !important;
    }
    .btnDemands {
        height:auto !important;
        &.noDemands {
            height: 30px !important;
        }
    }
    .adressField {
        display:flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .v-btn--disabled {
        background-color:rgba(0,0,0,0) !important;
        &.v-btn--has-bg {
            background-color:rgba(0,0,0,0) !important;
        }
    }
    .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
        background-color:rgba(0,0,0,0) !important;
    }
}
    .rdv {
        z-index:5;
        background-color:  #CCC;
        border-radius:3px;
        &.dragRdv {
            background-color: #61a2e2;
        }
        &.tempRdv {
            opacity:0.6
        }
        .inner-rdv {
            border-radius:3px;
            height:100%;
            user-select: none;
            overflow:hidden;
            font-weight:bold;
            display:flex;
            align-items: center;

        }
        .nextImpossibility, .previousImpossibility {
            background: repeating-linear-gradient(
                45deg,
                #be0000,
                #be0000 5px,
                #6f0000 5px,
                #6f0000 10px
            );
            border: 1px solid #6f0000
        }
        .previousImpossibility {
            position:absolute;
            width:100%;
        }
        
    }
    .titleRdv {
        align-items: center;
        margin-bottom: 0;
        width:100%;
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
</style>