<template>
    <v-dialog width="900" persistent v-model="displaySelectVendor">
        <v-card>
            <v-toolbar tile flat dark  class="bg-gradient">
                <v-toolbar-title>{{$t('choiceVendorTitle')}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn outlined depressed small class="mr-2" @click="$emit('closeVendorDialog')">{{$t('cancelLabel')}}</v-btn>
                <v-btn outlined depressed small @click="$emit('confirmVendorSelected', selectedVendor)">{{$t('confirmLabel')}}</v-btn>
            </v-toolbar>
            <v-card-text>
                <h4 style="border-bottom:0; margin-bottom:0" class="pb-0 mt-4">{{$t('availableVendorOnThisHours')}}</h4>
                <p class="mb-0 error--text" v-if="displayMsgInfo">{{$t('warningVendorCantVisio')}}</p>
                <div class="vendorItems" style="flex-wrap:wrap">
                    <div v-for="vendor in multi" @click="selectVendor(vendor.id)" :key="vendor.id" :class="selectedVendor == vendor.id ? 'vendorSelectorItem selected' : 'vendorSelectorItem' " style="flex-shrink:0">
                        <div class="selected" v-if="selectedVendor == vendor.id"></div>
                        <div>
                            <DonutCSS :stats="vendor.stats" :tooltip="false"/>
                        </div>
                        <v-tooltip right v-if="contact.assignment_user_id && contact.assignment_user_id.id && contact.assignment_user_id.id == vendor.id">
                            <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on" class="ml-3">
                                    <div class="name">
                                        <v-icon small class="mr-2" :color="vendor.color">$fullstar_s</v-icon>
                                        {{vendor.firstname}} {{vendor.name}}
                                        <v-icon small :color="vendor.confVisio ? 'success' : 'darkgrey'" class="ml-3" >{{vendor.confVisio ? '$webcam_r' : '$webcam_slash_r'}}</v-icon>
                                    </div>
                                    <p class="annotation">{{vendor.annotation}}</p>
                                    <v-chip class="mr-2" small outlined color="primary" v-for="(univers, index) in vendor.univers" :key="index">
                                        {{univers}}
                                    </v-chip>
                                </div>
                            </template>
                            <span>{{$t("assignedVendorLabel")}}</span>
                        </v-tooltip> 
                        <div class="ml-3" v-else>
                            <div class="name">
                                
                                <span class="pastille mr-2" :style="'background-color:'+vendor.color+';'"></span>
                                    {{vendor.firstname}} {{vendor.name}}
                                    <v-icon small :color="vendor.confVisio ? 'success' : 'darkgrey'" class="ml-3" >{{vendor.confVisio ? '$webcam_r' : '$webcam_slash_r'}}</v-icon>
                            </div>
                            <p class="annotation ml-4 mt-2">{{vendor.annotation}}</p>
                            <v-chip class="mr-2" small outlined color="primary" v-for="(univers, index) in vendor.univers" :key="index">
                                {{univers}}
                            </v-chip>
                        </div>



                    </div>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import DonutCSS from '@/components/DonutCSS'
export default {
    name:"SelectVendorRdvDialogComponent",
    components: {DonutCSS},
    props:['displaySelectVendor', 'multi', 'contact', 'rdvInfo'],
    data() {
        return {
            publicPath: process.env.BASE_URL,
            selectedVendor: "",
            displayMsgInfo: false,

        }
    },
    methods: {
        selectVendor(idVendor){
            if(this.rdvInfo.visio == "1"){
                let newVendor = this.multi.find((e) => e.id == idVendor)
                if(!newVendor.confVisio){
                    this.displayMsgInfo = true
                } else {
                    this.displayMsgInfo = false
                }
            } 
            this.selectedVendor = idVendor
        },
    },
    watch: {
        displaySelectVendor(val){
            this.displayMsgInfo = false
            if(val){
                if(this.rdvInfo && this.rdvInfo.links && this.rdvInfo.links.user && this.rdvInfo.links.user[0])
                this.selectedVendor = this.rdvInfo.links.user[0]
            }
        }
    },
}
</script>