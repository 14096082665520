<template>
    <div class="cell" :class="getClasses" 
        @mouseover.prevent="hoverAction($event)" 
        @click='addItem($event)'>   
        <span class="hour mr-3">{{parseFloatToHour(creneaux.start)}}</span> 
    </div>
</template>

<script>
import dateManipulation from '@/mixins/dateManipulation'
export default {
    name:"PlanningCell",
    props:['creneaux', 'dayText', 'hasOperationHere', 'type', 'settingUnavailableTemp', 'componentRef'],
    mixins:[dateManipulation],
    data() {
        return {
            hoverCell:false,
            
        }
    },
    computed: {
        //Ajoute des classes spécifique si c'est une cellule qui commence sur une heure (pour afficher une bordure) ou si c'est une cellule qui a une operation
        getClasses: function(){
            let classes = "";
            if(Number.isInteger(this.creneaux.start)){
                classes += " HourCell "
            }
            if(this.hasOperationHere && this.type != 'INDISPO_CONCESSION' && this.type != 'OPERATION_HOURS'){
                classes += " hasOperation "
            }
            if(this.hasOperationHere && this.type == 'DISPLAY_OPERATION_RDV' && this.$func.hasRight('lead/update_duration')){
                classes += " hasOperationBlue "
            }
            return classes
        },
    },
    methods: {
        //event pour afficher le rdv temporaire ou l'indispo temporaire dans le composant parent (Day.vue)
        hoverAction: function(event){
            if(this.type != "INDISPO_CONCESSION" && this.type != "INDISPO_USER" && this.type != "INDISPO_COACH" && this.type != "OPERATION_HOURS"){
                this.$emit('showTemp', event, event.srcElement.offsetTop)
            } else {
                if(!this.settingUnavailableTemp){
                    this.$emit('showUnavailableTemp', event, event.srcElement.offsetTop)
                }
            } 
        },
        //event d'ajout de rendez vous ou d'indispo au clic sur une cellule
        addItem: function(event){
            if(this.type != "INDISPO_CONCESSION" && this.type != "INDISPO_USER" && this.type != "INDISPO_COACH" && this.type != "OPERATION_HOURS"){
                this.$emit('addItem')
            } 
        }
    },
}
</script>
<style lang="scss">
    .cell {
        height:15px;
        font-size:12px;
        background-color:rgb(255,255,255);
        display: flex;
        align-items: center;
        justify-content:flex-end;
        .hour {
            font-weight:bold;
            display:none;
            //color:white;
        }
        &.even{
            background-color:#fff;
        }
        &.hasOperationBlue {
            background-color:rgba(25,118,210, 0.05);
            
        }
        &:last-child {
            border-bottom:1px solid #EEE;
        }
        &.HourCell {
            border-top:1px solid #EEE;
        }
        
    }
</style>