export default {
    methods: {
        //Regarde si une date a une ou plusieurs opérations
        hasOperation(dateFr, operationArray) {
            let hasOperation = false;
            operationArray.forEach(operation => {
                if(operation.dates.includes(dateFr)){
                    hasOperation = true
                }
            })
            return hasOperation
        },
        //Retourne un array d'opération remappé pour avoir un array des dates de l'opé
        getOperationsArray(operations, dateArray){
            let array= [];
            operations.forEach(operation => {
                if(operation.config.date_start && operation.config.date_end){
                    let startArray = operation.config.date_start.split(' ')
                    let endArray = operation.config.date_end.split(' ')
                    const duration = this.DaysBetween(this.parseDate(startArray[0]), this.parseDate(endArray[0]))
                    let operationData;
                    operationData = {
                        id: operation.id, 
                        type: operation.type, 
                        name: operation.name, 
                        unavailable: operation.unavailable,
                        status: operation.status,
                        start: new Intl.DateTimeFormat('fr-FR').format(this.parseDate(startArray[0])), 
                        end: new Intl.DateTimeFormat('fr-FR').format(this.parseDate(endArray[0])), 
                        fullDuration: duration
                    };
                    operationData.dates = [];
                    for (let index = 0; index < duration; index++) {
                        const element = new Date (new Date(startArray[0]).getTime() + (index*24*60*60*1000)) ;
                        if(dateArray.includes(new Intl.DateTimeFormat('fr-FR').format(element))){
                            operationData.dates.push(new Intl.DateTimeFormat('fr-FR').format(element))
                        }
                    }
                    operationData.duration = operationData.dates.length
                    if(operationData.dates.length > 0){
                        array.push(operationData)
                    }
                }
                
            });
            return array;
        },
        //retourne les slots indisponibles pour une date dépendant des datas du planning et des opérations
        getUnavailableSlots(dateFr, operationsArray, genericUnavailable, type){
            // if(this.hasOperation(dateFr, operationsArray) && type != "INDISPO_CONCESSION"){
            //     let unavailable = [];
            //     let operations = operationsArray.filter((e) => e.dates.includes(dateFr))
            //     operations.forEach(operation => {
            //         unavailable = unavailable.concat(operation.unavailable)
            //     })
            //     let slots = unavailable.filter((e) => e.date == this.parseFrDateToUs(dateFr))
            //     if(slots && slots.length > 0){
            //         return slots[0].slots
            //     } else {
            //         let unavailableGen = genericUnavailable.filter((e) => e.dayNumber == this.getBetterDay(new Date(this.parseFrDateToUs(dateFr))))
            //         if(unavailableGen && unavailableGen.length > 0){
            //             return unavailableGen[0].slots
            //         } else {
            //             return []
            //         }
            //     }
            // } else {
                if(genericUnavailable.length > 0){
                    let hebdomadaireUnavailable = genericUnavailable.filter((e) => e.type == "hebdomadaire" && e.day == this.getBetterDay(new Date(this.parseFrDateToUs(dateFr))))
                    let unavailableGen = genericUnavailable.filter((e) =>(e.date && e.date == this.parseFrDateToUs(dateFr)) && e.type != "hebdomadaire")
                    let unavailableEntries = hebdomadaireUnavailable.concat(unavailableGen)
                    if(unavailableEntries && unavailableEntries.length > 0){
                        return unavailableEntries
                    } else {
                        return []
                    }
                } else {
                    return []
                }
                
            // }
        },
        //Retourne les horaires de concession en fonction des ses indisponibilités
        
    }
}