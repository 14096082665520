<template>
    <div :class="type == 'INDISPO_CONCESSION' || type == 'OPERATION_HOURS' ? 'unavailableTemp hours' : 'unavailableTemp'" :style="setSize" >
        <span class="start">{{unavailableItemTemp.start}}</span>
        <span v-if="parseHourToFloat(unavailableItemTemp.end) - parseHourToFloat(unavailableItemTemp.start) > 0.25" class="end">{{unavailableItemTemp.end}}</span>
    </div>
</template>
<script>
import dateManipulation from '@/mixins/dateManipulation'
import planningMixins from '@/mixins/planningMixins'
export default {
    name : "PlanningUnavailableTempSlot",
    props: ["unavailableItemTemp", "type"],
    mixins:[dateManipulation, planningMixins],
    data() {
        return {
            
        }
    },
    methods: {
        
    },
    computed: {
        setSize: function (){
            return "height:"+(this.parseHourToFloat(this.unavailableItemTemp.end) - this.parseHourToFloat(this.unavailableItemTemp.start))*60+"px";
        }
    },
    watch: {
        
    },
}
</script>
<style lang="scss">
    .unavailableTemp {
        background-color: rgba(255, 150, 0, 0.4);
        width:100%;
        position:absolute;
        &.hours {
            background-color: rgba(90, 174, 126, 0.4);
        }
        span {
            position:absolute;
            pointer-events: none;
            user-select: none;
            line-height:normal;
        }
        .start {
            top:1px;
            right:2px;
        }
        .end {
            bottom:1px;
            right:2px;
        }
    }
</style>