<template>
    <v-dialog width="900" v-model="openSelectContactDialog" @click:outside="$emit('close')">
        <v-card>
            <v-toolbar tile flat dark class="bg-gradient">
                <v-toolbar-title>{{$t('choiceContactTitle')}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn outlined depressed small class="mr-2" @click="$emit('close')">{{$t('cancelLabel')}}</v-btn>
                <v-btn outlined depressed small @click="record">{{$t('confirmLabel')}}</v-btn>
            </v-toolbar>
            <v-card-text class="pt-4">
                <!-- <h4>CHOIX CONTACT</h4>
                <div class="pt-4" style="position:relative;">
                    <v-menu :value="menuOpen" offset-y :open-on-click="false" :close-on-click="false">
                        <template v-slot:activator="{}">
                            <v-text-field
                                filled
                                v-model="search"
                                :loading="loading"
                                dense
                                hide-details
                                placeholder="Recherchez : référence, nom, prénom, numéro de téléphone, adresse email">
                            </v-text-field>
                        </template>
                        <v-card>
                        <v-list dense class="mt-1">
                            <v-list-item dense v-for="(item, i) in items" :key="i" @click="selectItem(item)">
                                <v-list-item-content>
                                <v-list-item-title v-text="item.text"></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item dense v-if="totalItems > items.length">
                               <span class="warning--text" style="width:100%; text-align:center;">{{items.length}} résultats sur {{totalItems}} affichés. Affinez votre recherche</span>
                            </v-list-item>
                        </v-list>
                        </v-card>
                    </v-menu>
                </div> -->
                <div class="mt-5 accompDiv">
                    <span>Nombre d'accompagnants</span>
                    <span class="icons-Acc ml-2">
                        <v-icon dense v-for="(accomp, index) in 5" :key="index" @click="setAccompagnant(index)" :color="index < accompagnant ? 'success' : ''" class="mr-1 iconUser">{{accompagnant > index ? '$user_s' : '$user_l'}}</v-icon>
                    </span>
                </div>
                <div v-if="select != null">
                    <h4 class="mt-5">DEMANDE(S) à traiter durant le rendez-vous</h4>
                    <p class="error--text" v-if="errorTxt != ''">{{$t(errorTxt)}}</p>
                    <div v-if="demands.length > 0">
                        <div v-for="(demand, index) in demands" :key="'demand_'+index" :class="selectedDemands.includes(demand.id) ? 'mt-3 demandItem selected' : 'mt-3 demandItem'" @click="selectDemand(demand.id)">
                            <div class="name">#D{{demand.id}} - {{demand.name}}</div>
                            <div class="icon">
                                <v-btn text @click.stop="editRequest(demand.id, demand.type)" class="square">
                                    <v-icon small>$pencil_l</v-icon>
                                </v-btn>
                            </div>
                            <div v-if="selectedDemands.includes(demand.id)" class="selectedBg"></div>
                        </div>
                    </div>
                    <!-- <v-btn color="primary" depressed small class="mt-3">AJOUTER UNE DEMANDE</v-btn> -->
                    <v-menu v-if="this.$func.hasRight('lead/add')" offset-y left>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" small depressed color="primary" class="mt-3">
                                {{$t('addDemand')}}
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item v-for="type in demandTypes" :key="type.type" link dense @click="getForm(requestsFormUrl + type.type)">
                              <v-list-item-title>{{ $t(type.name) }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </v-card-text>
        </v-card>
        <FormDialogComponent
            v-if="form"
            ref="componentForm"
            :type="formType"
            :form="form"
            :action="'SET_DATA'"
        />
    </v-dialog>
</template>
<script>
import FormDialogComponent from "@/components/dialogs/FormDialogComponent";
import GenericDataService from '@/services/GenericDataService'
import getForm from "@/mixins/mixins";
export default {
    name : "SelectContactRdvDialogComponent",
    components: {FormDialogComponent},
    mixins: [getForm],
    props: ['openSelectContactDialog', 'contact', 'accompagnants', 'demandsSelected'],
    data() {
        return {
            publicPath: process.env.BASE_URL,
            loading: false,
            items: [],
            accompagnant: 0,
            search: null,
            select:null,
            menuOpen:false,
            totalItems:null,
            demands:[],
            selectedDemands: [],
            form:null,
            formType: "",
            errorTxt: "",
            demandTypes: [],
        }
    },
    // created() {
    //     if(this.contact && this.contact.hasOwnProperty('value') && this.demandsSelected && this.demandsSelected.length > 0){
    //         let ope_id;
    //         if(this.demandsSelected[0].operation_id.hasOwnProperty('id')){
    //             ope_id = this.demandsSelected[0].operation_id.id
    //         } else {
    //             ope_id = this.demandsSelected[0].operation_id
    //         }
    //         GenericDataService.postData('contact/'+this.contact.value+'/getDemandsByOperations?operation_id='+ope_id).then((response) => {
    //             this.demands = response.data.data[0].demands
    //         });
    //          if(this.demandTypes.length == 0){
    //             GenericDataService.getData('/hard_value/getValuesByType?type=demand_type').then((response) => {
    //                 let tmpTypes = response.data.data;

                    
    //                 for(const type in tmpTypes) {
    //                     tmpTypes[type].type = type;
    //                     this.demandTypes.push(tmpTypes[type]);
    //                 }
    //             });
    //         }
    //     }
        
    // },
    mounted() {
        if(this.contact && this.contact.hasOwnProperty('text') && this.demandsSelected && this.demandsSelected.length > 0){
            this.search = this.contact.text
            this.select = this._.cloneDeep(this.contact)
            let ope_id;
            if(this.demandsSelected[0].operation_id.hasOwnProperty('id')){
                ope_id = this.demandsSelected[0].operation_id.id
            } else {
                ope_id = this.demandsSelected[0].operation_id
            }
            GenericDataService.postData('contact/'+this.select.value+'/getDemandsByOperations?operation_id='+ope_id).then((response) => {
                if(response.data && response.data.data && response.data.data[0] && response.data.data[0].demands){
                    this.demands = response.data.data[0].demands
                }
                
            });
            if(this.demandTypes.length == 0){
                GenericDataService.getData('/hard_value/getValuesByType?type=demand_type').then((response) => {
                    let tmpTypes = response.data.data;

                    
                    for(const type in tmpTypes) {
                        tmpTypes[type].type = type;
                        this.demandTypes.push(tmpTypes[type]);
                    }
                });
            }
            
        }
        if(this.accompagnants > 0){
            this.accompagnant = this.accompagnants
        }
        if(this.demandsSelected && this.demandsSelected.length > 0){
            this.selectedDemands = this._.cloneDeep(this.demandsSelected)
            this.selectedDemands = this.selectedDemands.map((e) => e.id)
        }
    },
    watch: {
        demandsSelected(val){
            if(val.length > 0){
                let ope_id;
                if(this.demandsSelected[0].operation_id.hasOwnProperty('id')){
                    ope_id = this.demandsSelected[0].operation_id.id
                } else {
                    ope_id = this.demandsSelected[0].operation_id
                }
                GenericDataService.postData('contact/'+this.select.value+'/getDemandsByOperations?operation_id='+ope_id).then((response) => {
                    this.demands = response.data.data[0].demands
                })
            }
        },
        search(val){
            if( val && val.length > 2 && this.select == null){               
                setTimeout(() => {
                    this.searchForContact()
                }, 200);
            } 
            if(val == ""){
                this.select = null
                this.menuOpen = false
                this.items = []
                this.demands = []
            }
        }
    },
    computed: {
        requestsFormUrl() {
            if(this.select != null && this.select.value){
                let ope_id;
                if(this.demandsSelected[0].operation_id.hasOwnProperty('id')){
                    ope_id = this.demandsSelected[0].operation_id.id
                } else {
                    ope_id = this.demandsSelected[0].operation_id
                }
                return "/demand/_getFormNew?contact_id=" + this.select.value + "&operation_id="+ope_id+"&type="
            }
            
        }
    },
    methods: {
        record(){
            if(this.selectedDemands.length > 0){
                let payload = {}
                //payload.contact = this.select
                payload.demands = this.demands.filter((e) => this.selectedDemands.includes(e.id))
                payload.accompagnant = this.accompagnant
                setTimeout(() => {
                    this.$emit('saveContactAccompDemands', payload)
                }, 200);
            } else {
                this.errorTxt = 'selectDemandError'
            }
        },
        editRequest(id, type){
            this.getForm("/demand/"+id+"/getForm?formName="+type, id)
        },
        selectDemand(id){
            if(this.selectedDemands.includes(id)){
                let index = this.selectedDemands.findIndex((e) => e == id)
                this.selectedDemands.splice(index, 1)
            } else {
                this.selectedDemands.push(id)
            }
            this.errorTxt = ''
        },
        letMenuOpen(){
            this.menuOpen = false
            this.menuOpen = true
        },
        setAccompagnant(index){
            if(this.accompagnant == index +1){
                this.accompagnant = 0
            } else {
                this.accompagnant = index + 1
            }
            
        },
        selectItem(item){
            this.select = item;
            this.menuOpen = false
            this.search = item.text;
            GenericDataService.postData('contact/'+this.select.value+'/getDemands').then((response) => {
                this.demands = response.data.data.data
            })
        },
        searchForContact(){
            this.loading = true
            GenericDataService.postData('contact/searchContact', {search: this.search}).then(response => {
                this.items = response.data.data.data
                this.totalItems = response.data.data.total
                this.menuOpen = true
                this.$forceUpdate()
                this.loading = false
            })
        },
        customRefresh(){
            GenericDataService.postData('contact/'+this.select.value+'/getDemands').then((response) => {
                this.demands = response.data.data.data
            })
        }
    },
}
</script>
<style lang="scss">
    .labelSpec {
        position:absolute;
        top:7px;
        left:10px;
    }
    .accompDiv {
        display: flex;
        align-items: center;
    }
    .iconUser {
        &:after{
            display:none !important;
        }
    }
    .demandItem {
        position:relative;
        padding:15px;
        border:1px solid rgba(0,0,0,0.2);
        display:flex;
        justify-content: space-between;
        align-items: center;
        cursor:pointer;
        .icon{
            z-index:5;
        }
        &.selected {
            border-color: var(--v-success-base);
        }
        .selectedBg {
            position:absolute;
            z-index:0;
            width: 100%;
            height: 100%;
            top:0;
            left:0;
            background-color:rgba(91,184,93,0.2);
        }
    }
</style>