<template>
    <div :class="getClasses">
        <div class="source" v-if="getSource != ''">{{$t(getSource)}}</div>
    </div>
</template>
<script>
export default {
    name: "PlanningUnavailableSlot",
    props: ['unavailableIsLocked', "unavailableSlot", "type"],
    methods: {
        
    },
    computed: {
        getClasses: function(){
            let unavailableClass = "unavailable"
            if(this.unavailableIsLocked){
                unavailableClass += " locked"
            }
            if(this.unavailableSlot.source=='operation'){
                unavailableClass += " operation"
            }
            if(this.unavailableSlot.source=='user' && this.type== "INDISPO_USER" && this.type == "INDISPO_COACH"){
                unavailableClass += " user"
            }
            if(this.type == "INDISPO_CONCESSION" || this.type == "OPERATION_HOURS"){
                unavailableClass += " hours"
            }
            return unavailableClass
        },
        getSource: function(){
            switch (this.unavailableSlot.source) {
                case "user":
                    return "UserIndispoLabel"
                    break;
                case "operation":
                    return ""
                    break;
                case "entity":
                    if(this.type == "INDISPO_USER" || this.type == "INDISPO_COACH" || this.type == "PLANNING"){
                        return "EntityIndispoLabel"
                    } else {
                        return ""
                    }
                default:
                    return "UndefinedIndispoLabel"
                    break;
            }
        }
    },
}
</script>

<style lang="scss">
    .unavailable {
        position:absolute;
        z-index:2;
        width:5%;
        background-color:#EEE;
        // background: repeating-linear-gradient(
        //         45deg,
        //         #eee,
        //         #eee 5px,
        //         #CCC 5px,
        //         #CCC 10px
               
        //     );
        pointer-events: none;
        .source{
            display:none;
            position:absolute;
            top:50%;
            left: 50%;
            transform:translate(-50%, -50%);
            border:2px solid #CCC;
            padding:10px 20px;
            border-radius:10px;
            background-color: #eee;
            justify-content:center;
            align-items: center;
        }
        &.locked {
            width:100%;
            pointer-events: all;
        }
        &.no-pointer-event {
            pointer-events: none;
        }
        &.user {
            background: repeating-linear-gradient(
                45deg,
                #d2d3ef,
                #d2d3ef 5px,
                #abb0cb 5px,
                #abb0cb 10px
            );
        }
        &.unavailableTemp {
            background: repeating-linear-gradient(
                45deg,
                #e4a54a,
                #e4a54a 5px,
                #ff9600 5px,
                #ff9600 10px
            );
            //pointer-events: all;
        }
        &.predelete {
            background: repeating-linear-gradient(
                45deg,
                #e4a54a,
                #e4a54a 5px,
                #ff9600 5px,
                #ff9600 10px
            );
        }
        &.operation {
            padding:0 !important;
        }
        &.hours {
            background: rgba(0, 166, 81, 0.1);
            border: 0px solid rgba(0, 166, 81, 1);
            .source{
                display:none;
                position:absolute;
                top:50%;
                left: 50%;
                transform:translate(-50%, -50%);
                border:2px solid rgba(0, 166, 81, 1);
                padding:10px 20px;
                border-radius:10px;
                background-color: rgba(0, 166, 81, 1);
                justify-content:center;
                align-items: center;
                color:#FFF;
            }
        }
        &:hover {
            .source{
                display:inline-block;
            }
        }
        
        
    }
    $splits: (
        1: 90%,
        2: 45%,
        3: 30%,
        4: 22.5%
    );

    $positions: (
        0: 0,
        1: 45%,
        2: 30%,
        3: 22.5%
    );

    @each $split, $value in $splits {
        .unavailable[class*="split_#{$split}"] {
            width: $value !important;
        }
    }
    @each $position, $value in $positions {
        .unavailable[class*="position_#{$position}"] {
            left: $value !important;
        }
    }
</style>