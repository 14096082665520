export default class CalendarOrganizer {

    static run(list) {
        var self = this; // juste pour pouvoir utiliser le this dans la function
        var listMod = list.map(function (r) { return self.convertTimes(r); });
        listMod.sort(function (a, b) { return (a.min_start == b.min_start) ? b.min_duration - a.min_duration : a.min_start - b.min_start; }); // tri par ordre de début, en priorisant les plus grand crénaux

        var placed = [];

        var maxCols = 1;

        for (var i = 0; i < listMod.length; i++) {
            listMod[i].col = this.searchBestCol(listMod[i], placed);
            listMod[i].col_width = 1;
            listMod[i].max_cols = null; // prépare le maxCol (initialisé plus tard)
            maxCols = Math.max(maxCols, listMod[i].col + 1);
            placed.push(listMod[i]);
        }


        for (var i = 0; i < placed.length; i++) {
            placed[i].col_width = this.tryToExtend(placed[i], placed, maxCols);

        }


        // regroupe les rdv par pool pour sette le nombre de cols de chaque pool

        var pools = [];
        var currentPool = false;
        for (var i = 0; i < placed.length; i++) {
            if (currentPool == false) {
                currentPool = {
                    min_start: placed[i].min_start
                    , min_end: placed[i].min_end
                    , max_cols: placed[i].col + 1, rdvs: [i]
                };
            }
            else {
                if (this.intersect(currentPool, placed[i])) // on a un block qui intersecte
                {
                    currentPool.min_end = Math.max(currentPool.min_end, placed[i].min_end); // agrandi le pool
                    currentPool.max_cols = Math.max(currentPool.max_cols, placed[i].col + 1);  // sette la colone max (pour avoir le col_max)
                    currentPool.rdvs.push(i);
                }
                else {   // pas d'intersection, on passe à un nouveau pool
                    pools.push(currentPool);
                    currentPool = {
                        min_start: placed[i].min_start
                        , min_end: placed[i].min_end
                        , max_cols: placed[i].col + 1, rdvs: [i]
                    };
                }
            }
        }
        if (currentPool != false) // il reste un pool, on le rajoute
            pools.push(currentPool);



        for (var i = 0; i < pools.length; i++) {
            for (var j = 0; j < pools[i].rdvs.length; j++) {
                var id = pools[i].rdvs[j];
                placed[id].max_cols = pools[i].max_cols;
                placed[id].col_width = Math.min(placed[id].col_width, placed[id].max_cols - placed[id].col); // redefini la largeur max de la colone (comme on a regroupé en pools, ce n'est plus basé sur la totalité de la colone, mais bien parmis ceux du pool)

            }
        }
        return placed;
    }

    static searchBestCol(rdv, other) {
        for (var col = 0; col < 100; col++) {
            var collision = false;
            for (var i = 0; i < other.length; i++)
                if (other[i].col == col && this.intersect(rdv, other[i])) // collision, du coup, on utilise la col de ce bloc +1
                    collision = true;

            if (collision == false) // aucune collision trouvée sur cette colone, donc, on valide la position
                return col;
        }

        return col;

    }

    static tryToExtend(rdv, other, maxCols) {
        // pour chaque rdv, recherche les rdvs de cols supérieurs qui coliisinerai et prend la col la plus faible pour calculer la largeur possible

        var rightColFound = maxCols; // commence au max pour rechercher la largeur max

        for (var i = 0; i < other.length; i++) // on cherche pour tous les éléments qui ont une
            if (other[i].col > rdv.col && this.intersect(rdv, other[i])) // collision, du coup, on utilise la col de ce bloc +1
                rightColFound = Math.min(rightColFound, other[i].col);


        return rightColFound - rdv.col;  // la taille du bloc correspond à la col suivante - la col du rdv 
    }

    static intersect(blocA, blocB) {
        return blocA.min_end > blocB.min_start && blocA.min_start < blocB.min_end;
    }

    static convertTimes(rdv) {
        let start = rdv.date_start.split(' ')[1];
        rdv.start = start.split(':')[0]+":"+start.split(':')[1]
        let end = rdv.date_end.split(' ')[1];
        rdv.end = end.split(':')[0]+":"+end.split(':')[1]
        rdv.min_start = this.hourToMin(rdv.start, false);
        rdv.min_end = this.hourToMin(rdv.end, true);
        rdv.min_duration = rdv.min_end - rdv.min_start;
        return rdv;
    }

    static hourToMin(hour, isEndTime) {
        if (isEndTime && hour == '00:00') // pour gérer le cas du minuit
            hour = '24:00';
        return parseInt(hour.split(':')[0]) * 60 + parseInt(hour.split(':')[1]);
    }

}