<template>
  <div>
      <v-dialog
        transition="dialog-bottom-transition"
        fullscreen
        v-model="openThisModal"
      >
          <v-card>
            <v-toolbar
              color="primary"
              dark
            >
              {{title}}
            </v-toolbar>
            <v-card-text>
              <div class="pa-8">
                <v-row align-content="start">
                  <v-col cols="6">
                    <h3>Date du rendez-vous :</h3>
                  </v-col>
                  <v-col cols="6">
                    <p class="mb-0" v-if="appointementData.date !== undefined">
                      {{ new Intl.DateTimeFormat('fr-FR').format(this.parseDate(appointementData.date)) }}
                    </p>
                  </v-col>
                  <v-col cols="6">
                    <h3>Heure de début :</h3>
                  </v-col>
                  <v-col cols="6">
                    <p class="mb-0">{{appointementData.start}}</p>
                  </v-col>
                  <v-col cols="6">
                    <h3>Heure de fin :</h3>
                  </v-col>
                  <v-col cols="6">
                    <p class="mb-0">{{appointementData.end}}</p>
                  </v-col>
                </v-row>
                
              </div>
            </v-card-text>
            <v-card-actions class="justify-end pb-4">
              <v-btn
                text
                @click="closeModal"
              >Annuler</v-btn>
              <v-btn
                color="error"
                v-if="modalMode == 'Edit'"
                rounded
                depressed
                @click="deleteRdv"
              >Supprimer</v-btn>
              <v-btn
                color="primary"
                v-if="modalMode == 'Add'"
                rounded
                depressed
                @click="registerRdv"
              >Valider</v-btn>
              <v-btn
                color="primary"
                v-if="modalMode == 'Edit'"
                rounded
                depressed
                @click="editRdv"
              >Modifier</v-btn>
            </v-card-actions>
          </v-card>
      </v-dialog>
      <v-dialog
        v-model="deleteConfirmation"
        max-width="500px"
      >
        <v-card>
          <v-toolbar
              color="primary"
              dense
              dark
            >
               Êtes-vous sûr ?
            </v-toolbar>
          <v-card-actions class="justify-center pa-4">
            <v-btn
              text
              @click="deleteConfirmation = false"
            >
              Annuler
            </v-btn>
            <v-btn
              rounded
              depressed
              color="error"
              @click="confirmDelete"
            >
              Supprimer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>  
</template>
<script>
import dateManipulation from '@/mixins/dateManipulation'
import planningMixins from '@/mixins/planningMixins'
export default {
    name:"PlanningAppointementModal",
    props:['openModal', 'modalMode', 'appointementData'],
    mixins:[dateManipulation, planningMixins],
    data() {
      return {
        title:'',
        deleteConfirmation: false,
      }
    },
    beforeUpdate() {
      //set le titre de la popup en fonction du mode
      if(this.modalMode == "Add"){
        this.title = "Ajout d'un rendez-vous"
      } else {
        this.title = "Modifier le rendez-vous"
      }
    },
    mounted() {
      //set le titre de la popup en fonction du mode
      if(this.modalMode == "Add"){
        this.title = "Ajout d'un rendez-vous"
      } else {
        this.title = "Modifier le rendez-vous"
      }
    },
    computed: {
      openThisModal: {
        // getter
        get: function () {
          return this.openModal
        },
        // setter
        set: function (newValue) {
          this.$emit('closeModal')
        }
      }
    },
    methods: {
      //event de close de la modale
      closeModal: function(){
        this.$emit('closeModal')
      },
      //event d'update : TBD
      editRdv: function(event) {

      },
      //event d'effacement
      deleteRdv: function(event) {
        this.deleteConfirmation = true
      },
      //confirmation d'effacement
      confirmDelete: function(event) {
        this.deleteConfirmation = false
        this.$emit('deleteRdv', event, this.appointementData.id)
      },
      //event d'enregistrement d'un nouveau rendez vous
      registerRdv:function(event){
        let rdv = {
          id: Math.floor(Math.random()*90000) + 10000,
          date: this.appointementData.date,
          start: this.appointementData.start,
          end: this.appointementData.end
        }
        this.$emit('registerRdv', event, rdv)
      }
    },
}
</script>